const InstitutionOpen = require('../../assets/institutionOpen.svg');
const SpecialtiesOpen = require('../../assets/specialtiesOpen.svg');
const InstitutionClosed = require('../../assets/institutionClosed.svg');
const SpecialtiesClosed = require('../../assets/specialtiesClosed.svg');

module.exports = [
  {
    icon: InstitutionOpen,
    iconOff: InstitutionClosed,
    name: 'Institution',
    data: [
      'Licenses',
      'Authentication & Login',
      'Interfaces',
      'Facilities & Specialities',
      'U/S Systems',
      'Exam Types & CPT Codes',
      'Worksheets',
      'Map Exam Types & Worksheets',
      'Tags',
      'Map Tags',
      'Workflow',
      'User Groups',
      'Insights',
    ],
  },
  {
    icon: SpecialtiesOpen,
    iconOff: SpecialtiesClosed,
    name: 'Specialty',
    data: ['Members', 'Credentials', 'QA/Training'],
  },
];
