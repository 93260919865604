/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
const BaseComponent = require('./Base');
const {
  FETCH_SPECIALITIES,
  FETCH_FACILITIES,
  FETCH_CREDENTIALS,
  CACHE_US_UPDATE,
  FETCH_EXAM_TYPES,
  FETCH_ANNOTATIONS,
  FETCH_USER_GROUPS,
  FETCH_LDAP_GROUPS,
  FETCH_SPECIALTY_USER_GROUPS,
  FETCH_BILLING_CODES,
  FETCH_SPECIALTY_EXAM_TYPES,
  UPDATE_INSIGHT,
  TOGGLE_MODAL,
  TOAST,
  ERROR,
  CACHE_EXAMTYPE_UPDATE,
} = require('../events');

const eventBus = require('../utils/EventBus');
const FacilitiesAPI = require('../services/api/facilities');
const ClinicDefinitionAPI = require('../services/api/clinicdefinition');
const AccessControlAPI = require('../services/api/accesscontrol');

const facilitiesAPI = new FacilitiesAPI();
const clinicDefinitionService = new ClinicDefinitionAPI();
const accessControlService = new AccessControlAPI();
class Toggle extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
    this.attachEvents();
  }

  static get observedAttributes() {
    return ['enabled', 'isCPTEnabled'];
  }

  attachEvents() {
    this.shadowRoot.querySelector('input').addEventListener('click', async e => {
      e.stopPropagation();
      this.enabled = !this.enabled;
      if (this.isCPTEnabled === 'true' && this.enabled) {
        eventBus.fire(TOAST, {
          items: [
            {
              type: ERROR,
              message: 'Enabling this cpt code will disable your previous selection',
            },
          ],
        });
      }
      if (!this.card) return;
      let updateFunc;
      let fetchFunc;
      let enableAllFunc;
      let disableAllFunc;
      let action;
      switch (this.card) {
        case 'facilities': {
          updateFunc = async () =>
            await clinicDefinitionService
              .facilities({
                facilityId: this.id,
              })
              .patch({
                payload: {
                  isEnabled: this.enabled,
                },
              });
          fetchFunc = async () => await clinicDefinitionService.facilities().get();
          enableAllFunc = async () => await clinicDefinitionService.facilities().enableAll();
          disableAllFunc = async () => await clinicDefinitionService.facilities().disableAll();
          action = FETCH_FACILITIES;
          break;
        }
        case 'specialities': {
          updateFunc = async () => {
            if (!this.enabled) {
              eventBus.fire(TOGGLE_MODAL, {
                operation: 'disable',
                instance: {
                  card: this.card,
                  facilityId: this.facilityId,
                  specialtyId: this.id,
                  subText: 'Users associated with this specialty will be disabled',
                },
              });
            } else {
              await clinicDefinitionService
                .facilities({
                  facilityId: this.facilityId,
                })
                .specialties({ specialtyId: this.id })
                .patch({
                  payload: {
                    isEnabled: this.enabled,
                  },
                });
            }
          };
          fetchFunc = async () =>
            await clinicDefinitionService
              .facilities({ facilityId: this.facilityId })
              .specialties()
              .get();
          enableAllFunc = async () =>
            await clinicDefinitionService
              .facilities({ facilityId: this.facilityId })
              .specialties()
              .enableAll();
          disableAllFunc = async () =>
            await clinicDefinitionService
              .facilities({ facilityId: this.facilityId })
              .specialties()
              .disableAll();
          action = FETCH_SPECIALITIES;
          break;
        }
        case 'cpt': {
          updateFunc = async () =>
            this.enabled
              ? await clinicDefinitionService
                  .examTypes({
                    examTypeId: this.examTypeId,
                  })
                  .billingCodes({ billingCodeId: this.id })
                  .enable()
              : await clinicDefinitionService
                  .examTypes({
                    examTypeId: this.examTypeId,
                  })
                  .billingCodes({ billingCodeId: this.id })
                  .disable();
          fetchFunc = async () =>
            await clinicDefinitionService
              .examTypes({ examTypeId: this.examTypeId })
              .billingCodes()
              .getAll();
          enableAllFunc = async () =>
            this.enabled
              ? await clinicDefinitionService
                  .examTypes({ examTypeId: this.examTypeId })
                  .billingCodes()
                  .enableAll()
              : await clinicDefinitionService
                  .examTypes({ examTypeId: this.examTypeId })
                  .billingCodes()
                  .disableAll();
          action = FETCH_BILLING_CODES;
          break;
        }
        case 'systems': {
          updateFunc = async () => {
            const msg = {
              ultrasoundSystems: {
                enabled: this.enabled,
                specialtyId: this.specialtyId,
                usSystemId: this.id,
              },
            };
            eventBus.fire(CACHE_US_UPDATE, msg);
          };
          break;
        }
        case 'exams': {
          updateFunc = async () => {
            const msg = {
              examType: {
                enabled: this.enabled,
                facilityId: this.facilityId,
                specialtyId: this.specialtyId,
                examTypeId: this.examTypeId,
              },
            };
            eventBus.fire(CACHE_EXAMTYPE_UPDATE, msg);
          };
          fetchFunc = () =>
            clinicDefinitionService.examTypes({ facilityId: this.facilityId, specialtyId: this.specialtyId }).get();

          enableAllFunc = () => {
            const msg = {
              examType: {
                enabled: true,
                facilityId: this.facilityId,
                specialtyId: this.specialtyId,
              },
            };
            eventBus.fire(CACHE_EXAMTYPE_UPDATE, msg);
          };
          disableAllFunc = () => {
            const msg = {
              examType: {
                enabled: false,
                facilityId: this.facilityId,
                specialtyId: this.specialtyId,
              },
            };
            eventBus.fire(CACHE_EXAMTYPE_UPDATE, msg);
          };
          action = FETCH_SPECIALTY_EXAM_TYPES;
          break;
        }
        case 'annotations': {
          updateFunc = () =>
            facilitiesAPI.updateAnnotation({
              specialtyId: this.specialtyId,
              id: this.id,
              enabled: this.enabled,
            });
          fetchFunc = () => facilitiesAPI.getAnnotations(this.specialtyId);
          enableAllFunc = () => facilitiesAPI.enableAllAnnotations(this.specialtyId);
          action = FETCH_ANNOTATIONS;
          break;
        }
        case 'ugroup': {
          updateFunc = async () => {
            if (!this.enabled) {
              await clinicDefinitionService
                .facilities({ facilityId: this.facilityId })
                .specialties({ specialtyId: this.specialtyId })
                .userGroups()
                .delete({
                  id: this.id,
                });
            } else {
              await clinicDefinitionService
                .facilities({ facilityId: this.facilityId })
                .specialties({ specialtyId: this.specialtyId })
                .userGroups()
                .create({
                  payload: {
                    user_category_id: this.id,
                  },
                });
            }
          };
          fetchFunc = async () =>
            await clinicDefinitionService
              .facilities({ facilityId: this.facilityId })
              .specialties({ specialtyId: this.specialtyId })
              .userGroups()
              .get();
          enableAllFunc = async () =>
            await clinicDefinitionService
              .facilities({ facilityId: this.facilityId })
              .specialties({ specialtyId: this.specialtyId })
              .userGroups()
              .enableAll();
          disableAllFunc = async () =>
            await clinicDefinitionService
              .facilities({ facilityId: this.facilityId })
              .specialties({ specialtyId: this.specialtyId })
              .userGroups()
              .disableAll();
          action = FETCH_SPECIALTY_USER_GROUPS;
          break;
        }
        case 'ugroups': {
          updateFunc = async () =>
            await accessControlService.userGroups({ groupId: this.id }).patch({
              payload: {
                enabled: this.enabled,
              },
            });
          fetchFunc = async () => await accessControlService.userGroups().get();
          enableAllFunc = async () => await accessControlService.userGroups().enableAll();
          disableAllFunc = async () => await accessControlService.userGroups().disableAll();
          action = FETCH_USER_GROUPS;
          break;
        }
        case 'users': {
          updateFunc = async () =>
            this.enabled
              ? await accessControlService
                  .userGroups({ groupId: this.groupId })
                  .users()
                  .addToGroup({ specialtyId: this.specialtyId, id: this.id })
              : await accessControlService
                  .userGroups({ groupId: this.groupId })
                  .users({ id: this.id })
                  .delete();
          fetchFunc = async () => await accessControlService.userGroups({ groupId: this.groupId }).users().get;
          break;
        }
        case 'lgroup': {
          updateFunc = () =>
            facilitiesAPI.updateLdapGroup({
              specialtyId: this.specialtyId,
              id: this.id,
              enabled: this.enabled,
            });
          fetchFunc = () => facilitiesAPI.getLdapGroups(this.specialtyId);
          enableAllFunc = () => facilitiesAPI.enableAllLdapGroups(this.specialtyId);
          action = FETCH_LDAP_GROUPS;
          break;
        }
        case 'examTypeCPT': {
          updateFunc = async () =>
            await clinicDefinitionService.examTypes().patch({
              payload: {
                id: this.id,
                isEnabled: this.enabled,
              },
            });
          fetchFunc = async () => await clinicDefinitionService.examTypes().get();
          enableAllFunc = async () => await clinicDefinitionService.examTypes().enableAll();
          disableAllFunc = async () => await clinicDefinitionService.examTypes().disableAll();
          action = FETCH_EXAM_TYPES;
          break;
        }
        case 'cpt': {
          updateFunc = () =>
            facilitiesAPI.updateCPTCode({
              id: this.id,
              examTypeId: this.examTypeId,
              enabled: this.enabled,
            });
          fetchFunc = () => facilitiesAPI.getAllExamTypes();
          enableAllfunc = null;
          action = FETCH_EXAM_TYPES;
          break;
        }
        case 'insights': {
          updateFunc = () => {
            eventBus.fire(UPDATE_INSIGHT, {
              id: this.id,
              enabled: this.enabled,
            });
          };
          break;
        }
        case 'credentials': {
          userId = this.userId;
          examTypesIds = [this.id];

          updateFunc = async () => {
            !this.enabled
              ? await accessControlService
                  .users({ id: userId })
                  .credentialedExamTypes()
                  .revoke({
                    examTypesIds,
                  })
              : await accessControlService
                  .users({ id: userId })
                  .credentialedExamTypes()
                  .grant({
                    examTypesIds,
                  });
          };
          fetchFunc = async () =>
            await accessControlService
              .users({ id: userId })
              .credentialedExamTypes()
              .get();
          enableAllFunc = async () =>
            await accessControlService
              .users({ id: userId })
              .credentialedExamTypes()
              .grantAll();
          disableAllFunc = async () =>
            await accessControlService
              .users({ id: userId })
              .credentialedExamTypes()
              .revokeAll();
          action = FETCH_CREDENTIALS;
          break;
        }
        default:
          return null;
      }
      if (this.all) {
        if (!this.enabled && !!disableAllFunc) {
          try {
            await disableAllFunc();
          } catch (error) {
            eventBus.fire(TOAST, {
              items: [
                {
                  type: ERROR,
                  message: error,
                },
              ],
            });
          }
        } else {
          try {
            await enableAllFunc();
          } catch (error) {
            eventBus.fire(TOAST, {
              items: [
                {
                  type: ERROR,
                  message: error,
                },
              ],
            });
          }
        }
      } else if (!!updateFunc) {
        try {
          await updateFunc();
        } catch (error) {
          eventBus.fire(TOAST, {
            items: [
              {
                type: ERROR,
                message: error,
              },
            ],
          });
        }
      }
      eventBus.fire(action, {
        data: await fetchFunc(),
      });
    });
  }

  set enabled(newEnabled) {
    this.setAttribute('enabled', newEnabled);
  }

  get enabled() {
    return this.getAttribute('enabled') === 'true';
  }

  get isCPTEnabled() {
    return this.getAttribute('isCPTEnabled');
  }

  set isCPTEnabled(newIsCPTEnabled) {
    this.setAttribute('isCPTEnabled', newIsCPTEnabled);
  }

  set facilityId(newFacilityId) {
    this.setAttribute('facilityId', newFacilityId);
  }

  get facilityId() {
    return this.getAttribute('facilityId');
  }

  get specialtyId() {
    return this.getAttribute('specialtyId');
  }

  set specialtyId(newSpecialtyId) {
    this.setAttribute('specialtyId', newSpecialtyId);
  }

  get examTypeId() {
    return this.getAttribute('examTypeId');
  }

  set examTypeId(newExamTypeId) {
    this.setAttribute('examTypeId', newExamTypeId);
  }

  get userId() {
    return this.getAttribute('userId');
  }

  set userId(newUserId) {
    this.setAttribute('userId', newUserId);
  }

  set groupId(newGroupId) {
    this.setAttribute('groupId', newGroupId);
  }

  get groupId() {
    return this.getAttribute('groupId');
  }

  set card(newCard) {
    this.setAttribute('card', newCard);
  }

  get card() {
    return this.getAttribute('card');
  }

  set id(newId) {
    this.setAttribute('id', newId);
  }

  get id() {
    return this.getAttribute('id');
  }

  get disabled() {
    return this.getAttribute('disabled');
  }

  set disabled(newDisabled) {
    this.setAttribute('disabled', newDisabled);
  }

  set all(newAll) {
    this.setAttribute('all', newAll);
  }

  get all() {
    return this.getAttribute('all') !== null;
  }

  styles() {
    return `
    .form-switch {
      display: inline-block;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
    
    .form-switch i {
      position: relative;
      display: inline-block;
      margin-right: .5rem;
      width: 46px;
      height: 26px;
      background-color: #e6e6e6;
      border-radius: 23px;
      vertical-align: text-bottom;
      transition: all 0.3s linear;
    }
    
    .form-switch i::before {
      content: "";
      position: absolute;
      left: 0;
      width: 42px;
      height: 22px;
      background-color: #e0e0e0;
      border-radius: 11px;
      transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
      transition: all 0.25s linear;
    }
    
    .form-switch i::after {
      content: "";
      position: absolute;
      left: 0;
      width: 22px;
      height: 22px;
      background-color: #fff;
      border-radius: 11px;
      transform: translate3d(2px, 2px, 0);
      transition: all 0.2s ease-in-out;
    }
    
    .form-switch:active i::after {
      width: 28px;
      transform: translate3d(2px, 2px, 0);
    }

    .form-switch.disabled {
      opacity: 0.5;
    }

    .selected {
      margin-right: 18px;
    }
    
    .form-switch:active input:checked + i::after { transform: translate3d(16px, 2px, 0); }
    
    .form-switch input { display: none; }
    
    .form-switch input:checked + i { background-color: #4BD763; }
    
    .form-switch input:checked + i::before { transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0); }
    
    .form-switch input:checked + i::after { transform: translate3d(22px, 2px, 0); }
    `;
  }

  render() {
    return `
      <label class="form-switch ${this.disabled === 'true' ? 'disabled' : ''}">
        <input type="checkbox" ${this.enabled ? 'checked' : ''} ${this.disabled === 'true' ? 'disabled' : ''}>
        <i></i>
        <slot></slot>
      </label>
    `;
  }
}

customElements.define('exo-toggle', Toggle);
