// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TreeMenu = require('../components/TreeMenu');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExoContainer = require('../components/ExoContainer');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Modal = require('../components/Modal');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExoToast = require('../components/ExoToast');

const UserAPI = require('../services/api/user');
const userAPI = new UserAPI();

const menu = require('../data/sidebar');
const exoLogo = require('../../assets/exo.svg');
const { getMenu } = require('../utils/storage');
const exitLogo = require('../../assets/exit.svg');

module.exports = async () => {
  const { menu: m, option } = getMenu();
  const currentUser = await userAPI.getCurrentUser();
  const licenseAccess = currentUser.permissionNames.includes('LICENSE_ACCESS');
  return `
    <div id="container">
      <div id="sidebar">
      <svg width="30" height="19" id="logo">
          <image xlink:href="${exoLogo}" width="30" height="19" />
        </svg>
        <div class="sidebar-title">Admin Setup</div>
        ${menu
          .map(
            ({ name, icon, iconOff, data }, index) => `
          <tree-menu title="${name}" icon="${icon}" icon-off="${iconOff}" index="${index}" open="${name === m}">
            ${data
              .map(label => {
                if (label === 'Licenses' && !licenseAccess) {
                  return '';
                }
                return `<tree-menu-item label='${label}' selected="${label === option}"></tree-menu-item>`;
              })
              .join('')}
          </tree-menu>
        `,
          )
          .join('')}
        <svg id="close" width="150" height="50">
          <image xlink:href="${exitLogo}" />
        </svg>
      </div>
      <div id="content">
        <exo-container></exo-container>
      </div>
      <exo-modal hide></exo-modal>
      <exo-toast hide></exo-toast>
  </div>`;
};
