const BaseComponent = require('./Base');
const TriangleSVG = require('../../assets/triangle.svg');
const TriangleOff = require('../../assets/triangle-off.svg');
const eventBus = require('../utils/EventBus');
const { getMenu, setMenu } = require('../utils/storage');
const { UPDATE_MENU_TREE, UPDATE_CONTAINER_VIEW } = require('../events');

class TreeMenu extends BaseComponent {
  constructor() {
    super();
    this.titleDiv = this.shadowRoot.querySelector('.title');
    this._useEvents = true;
    this.attachEvents();
  }

  connectedCallback() {
    eventBus.register(UPDATE_MENU_TREE, ({ detail }) => {
      if (detail.id === this.index) {
        this.open = !this.open;
      }
    });
  }

  static get observedAttributes() {
    return ['open'];
  }

  set title(newTitle) {
    this.setAttribute('title', newTitle);
  }

  get title() {
    return this.getAttribute('title');
  }

  set open(isOpen) {
    this.setAttribute('open', isOpen);
  }

  get open() {
    return this.getAttribute('open') === 'true';
  }

  set index(newIndex) {
    this.setAttribute('index', newIndex);
  }

  get index() {
    return this.getAttribute('index');
  }

  set icon(newIcon) {
    this.setAttribute('icon', newIcon);
  }

  get icon() {
    return this.getAttribute('icon');
  }

  set iconOff(newIconOff) {
    this.setAttribute('icon-off', newIconOff);
  }

  get iconOff() {
    return this.getAttribute('icon-off');
  }

  attachEvents() {
    this.shadowRoot.querySelector('.title').addEventListener('click', () => {
      eventBus.fire(UPDATE_MENU_TREE, { id: this.index });
      const { option } = getMenu();
      setMenu({
        menu: this.title,
        option,
      });
    });
  }

  styles() {
    return `
      .title {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: ${this.open ? '#2C2C2E' : '#848484'};
      }

      .title:hover {
        cursor: pointer;
      }
      
      .tree-menu {
        margin-top: 10%;
      }

      .arrow {
        margin-top: 3%;
        margin-left: 3%;
      }

      .icon {
        margin-right: 5%;
      }
    `;
  }

  render() {
    return `
      <div class="tree-menu">
        <div class="title">
          <svg width="15" height="15" class="icon">
            <image xlink:href="${this.open ? this.icon : this.iconOff}" width="15" height="15"/>    
          </svg>
            ${this.title}
          <svg width="8" height="8" ${!this.open ? 'style="transform: rotate(-90deg)"' : ''} class="arrow">
            <image xlink:href="${this.open ? TriangleSVG : TriangleOff}" width="8" height="8" />
          </svg>
        </div>
        ${
          this.open
            ? `<ul>
          <slot></slot>
        </ul>`
            : ''
        }
      </div>
    `;
  }
}

class TreeMenuItem extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
    this.attachEvents();
  }

  updateContainerView() {
    eventBus.fire(UPDATE_CONTAINER_VIEW, { view: this.label });
  }

  connectedCallback() {
    eventBus.register(UPDATE_CONTAINER_VIEW, ({ detail }) => {
      if (detail.view === this.label) {
        this.selected = true;
        const { menu } = getMenu();
        setMenu({
          menu,
          option: this.label,
        });
        this.removeEventHandler('click', this.updateContainerView);
      } else {
        this.selected = false;
      }
    });
  }

  static get observedAttributes() {
    return ['selected'];
  }

  set selected(newSelected) {
    this.setAttribute('selected', newSelected);
  }

  get selected() {
    return this.getAttribute('selected') === 'true';
  }

  set label(newLabel) {
    this.setAttribute('label', newLabel);
  }

  get label() {
    return this.getAttribute('label');
  }

  attachEvents() {
    this.addEventListener('click', this.updateContainerView);
  }

  // OLD OFF:   #E5E5E5;
  styles() {
    return `
      li {
        white-space: nowrap;
        margin: 5% 0;
        list-style: none;
        font-style: normal;
        font-weight: ${this.selected ? 'bold' : 'normal'};
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: ${this.selected ? '#2C2C2E' : '#565656'};
        display: block;
        overflow: hidden;
        word-wrap:break-word;
        width: 130px;
        white-space: normal
      }

      li:hover {
        cursor: pointer;
      }
    `;
  }

  render() {
    return `
      <div>
      <li>${this.label}</li>
      </div>
    `;
  }
}

customElements.define('tree-menu-item', TreeMenuItem);
customElements.define('tree-menu', TreeMenu);
