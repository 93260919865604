/* eslint max-lines: 0 */

import { ITEMS_PER_PAGE } from '../utils/constants';

/* eslint max-lines-per-function: 0 */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = require('./Card');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toggle = require('./Toggle');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HorizontalCard = require('./HorizontalCard');
const isSafari = require('../utils/isSafari');
const dropDownSVG = require('../../assets/dropdown.svg');
const lensSVG = require('../../assets/lens.svg');
const ClinicDefinitionAPI = require('../services/api/clinicdefinition');
const AccessControlAPI = require('../services/api/accesscontrol');
const CSVImporter = require('../services/api/csvImport');
const DownloadCSV = require('../services/api/downloadCSV');

const {
  TOAST,
  ERROR,
  WARNING,
  SUCCESS,
  FETCH_LDAP_GROUPS,
  FETCH_FACILITIES,
  FETCH_SPECIALITIES,
  FETCH_USER_GROUPS,
  FETCH_LDAP_GROUP_USERS,
  UPDATE_SELECTED_FACILITY,
  UPDATE_SELECTED_SPECIALTY,
  UPDATE_SELECTED_USER_GROUP,
  UPDATE_CRUD_OPERATION,
  FETCH_SPECIALTY_USER_GROUPS,
  FETCH_ALL_USERS,
  IMPORT_CSV,
  PAGINATE_MEMBERS_USERS,
} = require('../events');

const eventBus = require('../utils/EventBus');
const clinicDefinitionService = new ClinicDefinitionAPI();
const accessControlService = new AccessControlAPI();

export const ALWAYS_ENABLED_GROUPS = ['Clinical Admin', 'Exo', 'IT'];

class Members extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
    this._useScroll = true;
    this.all_users_offset = 0;
    this.specialty_users_offset = 0;
    this.fetching = false;
  }

  static get observedAttributes() {
    return ['selectedspecialty', 'selectedfacility', 'selectedusergroup', 'operation', 'userfilter', 'showenabled'];
  }

  attachEvents() {
    const showEnabledToggle = this.shadowRoot.querySelector('.show-enabled');
    const facilitySelect = this.shadowRoot.querySelector('.facility-select');
    const specialtySelect = this.shadowRoot.querySelector('.specialty-select');
    const importCSVButton = this.shadowRoot.querySelector('#file');
    const downloadCSVLink = this.shadowRoot.querySelector('.csv-users');
    const downloadCSVTemplateLink = this.shadowRoot.querySelector('.csv-template');
    const searchInput = this.shadowRoot.querySelector('.user-search');

    if (showEnabledToggle) {
      showEnabledToggle.addEventListener('click', () => {
        this.showEnabled = this.showEnabled === 'true' ? 'false' : 'true';
      });
    }

    facilitySelect.addEventListener('change', () => {
      eventBus.fire(UPDATE_SELECTED_FACILITY, {
        facilityId: facilitySelect.value,
      });

      eventBus.fire(UPDATE_SELECTED_SPECIALTY, {
        specialtyId: null,
      });

      eventBus.fire(FETCH_LDAP_GROUP_USERS, {
        data: [],
      });
    });

    if (searchInput) {
      if (this.userFilter) {
        searchInput.defaultValue = this.userFilter;
        const filterLength = this.userFilter.length;
        searchInput.setSelectionRange(filterLength, filterLength);
      }

      searchInput.addEventListener('keyup', e => {
        if (e.key === 'Enter') {
          this.userFilter = this.currentFilter;
        } else {
          this.currentFilter = e.currentTarget.value;
        }
      });
      searchInput.focus();
    }

    const downloadCSVhandler = content => async () => {
      const downloadCSV = new DownloadCSV();

      const file = content === 'template' ? downloadCSV.template().get() : downloadCSV.users().get();
      try {
        const data = await file;
        const csvFile = new Blob([data], { type: 'text/csv' });
        const downloadLink = document.createElement('a');
        downloadLink.download = 'users_import_template.csv';
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = 'none';

        document.body.appendChild(downloadLink);
        downloadLink.click();
      } catch (error) {
        eventBus.fire(TOAST, {
          type: ERROR,
          message: error,
          seconds: 30,
        });
      }
    };

    downloadCSVTemplateLink.addEventListener('click', downloadCSVhandler('template'));

    downloadCSVLink.addEventListener('click', downloadCSVhandler('users'));

    specialtySelect.addEventListener('change', () => {
      eventBus.fire(UPDATE_SELECTED_SPECIALTY, {
        specialtyId: specialtySelect.value,
      });

      this.selectedUserGroup = null;

      eventBus.fire(FETCH_LDAP_GROUP_USERS, {
        data: [],
      });
    });

    if (importCSVButton) {
      importCSVButton.addEventListener('change', () => {
        eventBus.fire(IMPORT_CSV, { input: importCSVButton });
      });
    }
  }

  async connectedCallback() {
    eventBus.register(UPDATE_CRUD_OPERATION, ({ detail }) => {
      this.selectedCard = detail.card;
      this.operation = detail.operation;
    });

    eventBus.registerOnce(UPDATE_SELECTED_FACILITY, async ({ detail }) => {
      this.selectedFacility = detail.facilityId;
      this.selectedSpecialty = null;
      this.selectedUserGroup = null;
      eventBus.fire(FETCH_USER_GROUPS, {
        data: [],
      });
      eventBus.fire(FETCH_SPECIALITIES, {
        data: await clinicDefinitionService
          .facilities({ facilityId: detail.facilityId })
          .specialties()
          .get({
            parameters: {
              is_enabled: true,
            },
          }),
      });
    });

    eventBus.registerOnce(UPDATE_SELECTED_SPECIALTY, async ({ detail }) => {
      this.selectedSpecialty = detail.specialtyId;
      this.selectedUserGroup = null;
      if (detail.specialtyId === null) {
        return;
      }
      eventBus.fire(FETCH_USER_GROUPS, {
        data: await accessControlService.userGroups().get({ parameters: { enabled: true } }),
      });

      eventBus.fire(FETCH_SPECIALTY_USER_GROUPS, {
        data: await await clinicDefinitionService
          .facilities({ facilityId: this.selectedFacility })
          .specialties({ specialtyId: detail.specialtyId })
          .userGroups()
          .get(),
      });
    });

    eventBus.registerOnce(UPDATE_SELECTED_USER_GROUP, async ({ detail }) => {
      this.selectedUserGroup = detail.groupId;
      this.userFilter = '';
    });

    eventBus.registerOnce(IMPORT_CSV, async ({ detail }) => {
      this._data.isLoading = true;

      if (detail.input && detail.input.files[0]) {
        const formData = new FormData();
        formData.append('file', detail.input.files[0]);

        const importer = new CSVImporter();

        this._data.isLoading = false;

        try {
          const { data } = await importer.process(formData);
          const newUsers = data['New users'];
          const existingUsers = data['Existing users'];
          const errorUsers = data['Error users'];
          const errors = data['Error messages'];
          eventBus.fire(TOAST, {
            items: [
              ...(newUsers ? [{ type: SUCCESS, message: `New users: ${newUsers}` }] : []),
              ...(existingUsers ? [{ type: WARNING, message: `Existing users: ${existingUsers}` }] : []),
              ...(errorUsers && errors
                ? [
                    {
                      type: ERROR,
                      message: `Error users: ${errorUsers}</br> Errors: </br>${errors
                        .map(error => `- ${error}</br>`)
                        .join('')}`,
                    },
                  ]
                : []),
            ],
            seconds: 10,
          });
        } catch (error) {
          eventBus.fire(TOAST, {
            type: ERROR,
            message: error,
          });
        }

        eventBus.fire(FETCH_FACILITIES, {
          data: await clinicDefinitionService.facilities().get({
            parameters: {
              is_enabled: true,
            },
          }),
        });

        if (this.selectedUserGroup !== 'null') {
          eventBus.fire(FETCH_LDAP_GROUP_USERS, {
            data: await accessControlService
              .userGroups({ groupId: this.selectedUserGroup })
              .users()
              .get({ parameters: { specialtyId: this.selectedSpecialty } }),
          });
        }

        eventBus.fire(FETCH_ALL_USERS, {
          data: await accessControlService
            .userGroups()
            .users()
            .getAll({ parameters: { limit: ITEMS_PER_PAGE } }),
        });
      }
    });

    this.listenForEvents(
      [
        { event: FETCH_FACILITIES, key: 'facilities' },
        { event: FETCH_SPECIALITIES, key: 'specialities' },
        { event: FETCH_USER_GROUPS, key: 'groups' },
        { event: FETCH_SPECIALTY_USER_GROUPS, key: 'specialty_ugroups' },
        { event: FETCH_LDAP_GROUPS, key: 'ldap' },
        { event: FETCH_LDAP_GROUP_USERS, key: 'users' },
        { event: FETCH_ALL_USERS, key: 'all_users' },
        { event: IMPORT_CSV, key: 'import_csv' },
      ],
      eventBus,
    );

    eventBus.fire(FETCH_FACILITIES, {
      data: await clinicDefinitionService.facilities().get({
        parameters: {
          is_enabled: true,
        },
      }),
    });

    eventBus.fire(FETCH_ALL_USERS, {
      data: await accessControlService
        .userGroups()
        .users()
        .getAll({ parameters: { limit: ITEMS_PER_PAGE } }),
    });

    eventBus.registerOnce(PAGINATE_MEMBERS_USERS, async ({ detail }) => {
      if (!this.fetching) {
        this.usersListPosition = detail.listPosition;
        this.fetching = true;
        this.all_users_offset = this._data.all_users.results.length;
        this.specialty_users_offset = this._data.users.results.length;

        const allUsersData = await accessControlService
          .userGroups()
          .users()
          .getAll({ parameters: { offset: this.all_users_offset, limit: ITEMS_PER_PAGE } });

        eventBus.fire(FETCH_ALL_USERS, {
          data: {
            ...allUsersData,
            results: [...this._data.all_users.results, ...allUsersData.results],
          },
        });

        if (
          this.all_users_offset >= this.specialty_users_offset &&
          this.specialty_users_offset <= this._data.users.count
        ) {
          const userGroupUsersData = await accessControlService
            .userGroups({ groupId: this.selectedUserGroup })
            .users()
            .get({
              parameters: {
                specialtyId: this.selectedSpecialty,
                limit: ITEMS_PER_PAGE,
                offset: this.specialty_users_offset,
              },
            });

          eventBus.fire(FETCH_LDAP_GROUP_USERS, {
            data: {
              ...userGroupUsersData,
              results: [...this._data.users.results, ...userGroupUsersData.results],
            },
          });
        }

        this.fetching = false;
      }
    });
  }

  get selectedFacility() {
    return this.getAttribute('selectedFacility');
  }

  set selectedFacility(newSelectedFacility) {
    this.setAttribute('selectedFacility', newSelectedFacility);
  }

  get selectedSpecialty() {
    return this.getAttribute('selectedSpecialty');
  }

  set selectedSpecialty(newSelectedSpecialty) {
    this.setAttribute('selectedSpecialty', newSelectedSpecialty);
  }

  get selectedUserGroup() {
    return this.getAttribute('selectedUserGroup');
  }

  set selectedUserGroup(newSelectedUserGroup) {
    this.usersListsPosition = 0;
    this.setAttribute('selectedUserGroup', newSelectedUserGroup);
  }

  set operation(newOperation) {
    this.setAttribute('operation', newOperation);
  }

  get operation() {
    return this.getAttribute('operation') || 'view';
  }

  get usersListPosition() {
    return this.getAttribute('usersListPosition');
  }

  set usersListPosition(newPosition) {
    this.setAttribute('usersListPosition', newPosition);
  }

  get offset() {
    return this.getAttribute('offset');
  }

  set offset(newOffset) {
    this.setAttribute('offset', newOffset);
  }

  set showEnabled(newShowEnabled) {
    this.setAttribute('showEnabled', newShowEnabled);
  }

  get showEnabled() {
    return this.getAttribute('showEnabled');
  }

  get userFilter() {
    return this.getAttribute('userFilter');
  }

  set userFilter(newUserFilter) {
    this.setAttribute('userFilter', newUserFilter);
  }

  styles() {
    return `
      .title {
        margin-bottom: 3%;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 50px;
        letter-spacing: 0.566667px;
        color: #2C2C2E;
      }

      .csv-buttons {
        width: 40%;
        display: inline-block;
      }

      .select-box {
        width: 28%;
        display: inline-block;
      }
    
      .members-wrapper {
        position: relaive;
      }

      .import-box {
        vertical-align: top;
      }

      .confirm {
        border-color: #007AFF;
        color: #007AFF;
        font-size: 15px;
        margin-left: 2%;
        display: inline-block;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      button {
        border: 2px solid black;
        background-color: white;
        border-radius: 5px;
        color: black;
        padding: 14px 28px;
        font-size: 16px;
        cursor: pointer;
      }

      select {
        border: 1px solid #007aff;
        background-color: #ffffff;
        border-radius: 10px;
        width: 100%;
        height: 53px;
        float: left;
        padding: 6px 35px;
        outline: none;
        -webkit-appearance: none;
        -ms-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin-left: 5%;
        font-size: 15px;
      }

      exo-card {
        max-height: 500px;
      }

      .select-wrapper {
        width: 90%;
        margin-top: 2%;
        background-color: #FFF;
        display: inline-block;
        position: relative;
      }

      .csv-wrapper {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin-top: 1%;
        background-color: #FFF;
        margin-left: 5%;
      }

      .csv-import {
        border-radius: 10px;
        display: flex;
        justify-content: flex-end;
        width: 30%;
        height: 53px;
      }

      .download-link {
        color: #007AFF;
        font-size: 15px;
        text-align: center;
        width: 30%;
        border: 1px solid #007AFF;
        background-color: white;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        padding: 5px;
      }
      
      .select-wrapper::before {
        content: url(${dropDownSVG});
        position: absolute;
        right: 0%;
        top: 27%;
        pointer-events: none;
      }

      exo-card-title {
        padding-left: 6%;
        margin-bottom: 10%;
        ${isSafari() ? 'width: 50%;' : ''}
      }

      .title-wrapper {
        margin-left: 6%;
        margin-bottom: 4%;
      }

      .head-section {
        display: flex;
        justify-content: space-between;
        padding-top: 5%;
      }

      .divider {
        margin: 0 auto;
        width: 90%;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }

      .items {
        padding: 0;
        width: 100%;
        margin-top: 1%;
        max-height: 400px;
        overflow: scroll;
      }

      .enable-all {
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: normal;
        width: 90%;
        margin: 0 auto;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
        padding-bottom: 3%;
      }

      .show-enabled-container {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
      }

      .show-enabled-label { 
        font-size: 12px;
        font-weight: 400;
        color: rgba(44, 44, 46, 0.503087);
        margin-right: 4px;
      }

      .card-container {
        margin-top: 3%;
      }

      .enable-all-label {
        margin-top: 1%;
        margin-right: 2%;
        padding-bottom: 5%;
      }

      .enable-all-label {
        margin-top: 1%;
        margin-right: 2%;
      }

      #file {
        display: none;
      }

      label {
        border: 1px solid black;
        background-color: white;
        border-radius: 10px;
        color: black;
        padding: 10px;
        font-size: 16px;
        cursor: pointer;
        margin-left: 10%;
        text-align: center;
      }

      exo-card-list-item:hover {
        cursor: pointer;
      }

      horizontal-card {
        flex-grow: 1;
        margin-right: 25px;
      }

      .top-card-container {
        display: flex;
      }
      
      exo-card-button {
        margin-right: 10px;
      }

      .message-wrapper {
        position: absolute;
        right: 25px;
      }

      .warning-message {
        width: 330px;
        padding: 22px;
        border: 1px solid #FB5654;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 25px;
        align-items: center;
        display: flex;
      }
      .csv-instructions {
        width: 266px;
        padding: 22px 32px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(74, 74, 139, 0.100524);
        border-radius: 5px;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
      }

      .card-container-wrapper {
        position: relative;
      }

      .card-container {
        display: flex;
      }

      exo-card {
        width: 25%;
      }

      .card-container exo-card:nth-child(3) {
        margin-left: 2%;
      }

      .buttons {
        width: 30%;
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: flex-end;
      }

      .button-icon {
        margin: 30% 5%;
      }

      .card-content {
        height: 70vh;
        display: flex;
        flex-direction: column;
      }
      
      ul {
        overflow: auto;
      }

      .users {
        height: 60px;
      }

      .users-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px;
      }

      .users-count {
        margin-left: 8px;
        white-space: nowrap;
        color: rgba(44, 44, 46, 0.5031);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }

      .search-container {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        height: 26px;
        max-width: 160px;
        background: #F2F2F7;
        border-radius: 5px;
      }

      .lens {
        margin-left: 10px;
      }

      input, input:focus {
        width: 70%;
        margin-left: 4px;
        border: none;
        outline: none;
        background: #F2F2F7;
      }
    `;
  }

  handleScroll() {
    const usersList = this.shadowRoot.querySelector('#users-list');

    if (usersList) {
      usersList.addEventListener('scroll', event => {
        this.usersListPosition = event.path[0].scrollTop;
      });

      usersList.scrollTo({ top: this.usersListPosition });
    }
  }

  isEnabled(item, card) {
    if (card === 'ugroup' && this._data && this._data.specialty_ugroups) {
      return !!this._data.specialty_ugroups.find(i => i.id === item.id);
    } else if (card === 'users' && this._data && this._data.users) {
      return item.inGroup;
    } else {
      return item.isEnabled;
    }
  }

  allEnabled(card) {
    if (card === 'ugroup' && this._data && this._data.specialty_ugroups && this._data.groups) {
      return this._data.groups.every(item => this._data.specialty_ugroups.some(group => item.id === group.id));
    }
  }

  getFilteredUsers(items) {
    const lowerCaseFilter = this.userFilter.toLowerCase();
    return items.filter(user => user.username.toLowerCase().includes(lowerCaseFilter)
      || (user.firstName && user.firstName.toLowerCase().includes(lowerCaseFilter)) 
      || (user.lastName && user.lastName.toLowerCase().includes(lowerCaseFilter)));
  }

  renderExoCard({
    title,
    divider,
    items,
    card,
    operation,
    nameKey,
    placeholderTitle,
    selectedItem,
    pagination,
    totalCount,
  }) {
    if (card === 'users') {
      if (this.userFilter) {
        items = this.getFilteredUsers(items);
      }
      if (this.showEnabled === 'true') {
        items = items.filter(item => this.isEnabled(item, card));
      }
    }

    return `
      <exo-card
        class="${this.selectedCard !== card && this.operation !== 'view' ? 'blur' : ''}" page="members"
        ${pagination ? `pagination totalCount=${totalCount} itemscount=${items.length} offset=${items.length}` : ''} >
        <div class='card-content'>
          <div class="head-section ${card}">
            <exo-card-title ${
              placeholderTitle ? `placeholderTitle="${placeholderTitle}"` : ''
            } title="${title}"></exo-card-title>
            ${
              card === 'users'
                ? `<div class="show-enabled-container">
                <span class="show-enabled-label">Show Enabled</span>
                <exo-toggle
                  enabled="${this.showEnabled}"
                  class="show-enabled"
                ></exo-toggle>
              </div>`
                : ''
            }
          </div>

          ${
            card === 'users'
              ? `
                <div class="users-container">
                  <div class="search-container">
                    <svg height="13" width="13" class="lens">
                      <image xlink:href="${lensSVG}" height="13" width="13" />
                    </svg>
                    <input type="text" class="user-search" placeholder="Search User" />
                  </div>
                  <div class="users-count">${items.length} Users</div>
                </div >
                `
              : ''
          }
        
          <div class="divider">
            ${divider.map(d => `<span>${d}</span>`).join('')}
          </div>

          <ul class="items paginated" id="${card === 'users' ? 'users-list' : ''}">
            ${items
              .map(
                item =>
                  `<exo-card-list-item
                    class='users'
                    page="members"
                    name="${item.name}"
                    operation="${operation}"
                    id="${item.id}"
                    card="${card}"
                    model="${item.model}"
                    enabled="${this.isEnabled(item, card)}"
                    facilityId="${this.selectedFacility}"
                    specialtyId="${this.selectedSpecialty}"
                    groupId="${this.selectedUserGroup}"
                    selectedItem="${selectedItem}"
                    disabled=${item.disabled || card === 'users'}
                    ${card === 'users' ? `email="${item.email}"` : ''}
                    ${card === 'users' ? `username="${item.username}"` : ''}
                    ${card === 'users' ? `firstName="${item.firstName}"` : ''}
                    ${card === 'users' ? `lastName="${item.lastName}"` : ''}
                    ${card === 'ugroup' ? `clickable="true"` : ''}
                  >
                    ${
                      card === 'users' && item.lastName && item.firstName
                        ? `${item.lastName}, ${item.firstName}`
                        : item[nameKey]
                    }
                  </exo-card-list-item>`,
              )
              .join('')}
          </ul>
          <div>
          </div>
          ${
            card !== 'users' && operation === 'view' && items.length > 0
              ? `<div class="enable-all">
                <span class="enable-all-label">${this.allEnabled(card) ? 'Disable All' : 'Enable All'}</span>
                <exo-toggle
                  card="${card}"
                  facilityId="${this.selectedFacility}"
                  specialtyId="${this.selectedSpecialty}"
                  groupId="${this.selectedUserGroup}"
                  all
                  enabled="${this.allEnabled(card)}"
                  ></exo-toggle>
                </div>`
              : ''
          }
        </div>
      </exo-card>
    `;
  }

  renderInputFile() {
    if (this._data && this._data.isLoading) {
      return 'Loading...';
    }

    return `
      <div class="csv-import">
        <input type="file" id="file" accept=".csv"/>
        <label for="file" class="confirm import-btn">
          Import CSV
        </label>
      </div>
    `;
  }

  render() {
    return `
      <div class="members-wrapper">
        <div class="title">Members</div>
        <div class="top-card-container">
          <horizontal-card page="members">
            <div class="csv-buttons import-box">
              <div class="title-wrapper">
                <exo-card-title placeholderTitle="Import" title="CSV"></exo-card-title>
              </div>
              <div class="csv-wrapper">
              <button class="download-link csv-template" download>Download CSV Template</button>
              ${this.renderInputFile()}
              <button class="download-link csv-users" download>Download CSV</button>
            </div>
              </div>
              
             <div class="select-box">
                <div class="title-wrapper">
                 <exo-card-title placeholderTitle="Select the" title="Facility"></exo-card-title>
                </div>
               <div class="select-wrapper">
                  <select class="facility-select">
                  <option selected="selected" disabled>Select</option>
                     ${this._data &&
                       this._data.facilities &&
                       this._data.facilities
                         .map(
                           facility =>
                             `<option value="${facility.id}" ${
                               facility.id == this.selectedFacility ? 'selected' : ''
                             }> ${facility.name} </option>`,
                         )
                         .join('')}
                  </select>
                </div>
             </div>
             <div class="select-box">
                <div class="title-wrapper">
                 <exo-card-title placeholderTitle="Select the" title="Specialty"></exo-card-title>
                </div>
               <div class="select-wrapper">
                 <select class="specialty-select" ${
                   this._data && (!this._data.specialities || this._data.specialities.length === 0) ? 'disabled' : ''
                 }>
                 <option selected="selected" disabled>Select</option>
                   ${this._data &&
                     this._data.specialities &&
                     this._data.specialities
                       .map(
                         specialty =>
                           `<option value="${specialty.id}"  ${
                             specialty.id == this.selectedSpecialty ? 'selected' : ''
                           }> ${specialty.name} </option>`,
                       )
                       .join('')}
                 </select>
                </div>
              </div>

                
          </horizontal-card>
        </div>
        <div class="card-container-wrapper">
          <div class="card-container">
            <div class="message-wrapper">
              <ol class="csv-instructions">
                <li>Download CSV template using &ldquo;Download CSV Template&rdquo; button</li>
                <li>Use the instructions in the template to fill it out</li>
                <li>Import the template using &ldquo;Import CSV&ldquo;</li>
                <li>Download CSV of current Exo Works users</li>
              </ol>
              <div class="warning-message">
                A user can only belong to one user group. User will be removed from the first user group if a second user group is selected.       
              </div>
            </div>
            ${
              this._data && this._data.groups && this._data.groups.length
                ? this.renderExoCard({
                    card: 'ugroup',
                    title: 'User Group',
                    nameKey: 'name',
                    divider: ['User Group', 'Enabled'],
                    operation: 'view',
                    items: this._data.groups.map(group => ({
                      ...group,
                      disabled: ALWAYS_ENABLED_GROUPS.some(item => item === group.name),
                    })),
                    selectedItem: this.selectedUserGroup,
                  })
                : ''
            }

            ${
              this._data && this._data.ldap && this._data.ldap.length
                ? this.renderExoCard({
                    card: 'lgroup',
                    title: 'LDAP Group',
                    nameKey: 'name',
                    divider: ['LDAP Group', 'Enabled'],
                    operation: 'view',
                    items: this._data.ldap,
                  })
                : ''
            }

            ${
              this._data &&
              JSON.parse(this.selectedUserGroup) &&
              this._data.all_users &&
              this._data.all_users.results.length
                ? this.renderExoCard({
                    card: 'users',
                    placeholderTitle: 'List of',
                    title: 'Users',
                    nameKey: 'username',
                    divider: ['Members', 'Enabled'],
                    operation: this.operation,
                    pagination: this.showEnabled !== 'true' && !this.userFilter,
                    totalCount: this._data.all_users.count,
                    items: this._data.all_users.results
                      .filter((item, index, self) => index === self.findIndex(t => t.id === item.id))
                      .map(user => ({
                        ...user,
                        inGroup:
                          this._data.users.results &&
                          this._data.users.results.length &&
                          this._data.users.results.some(groupUser => groupUser.id === user.id),
                      })),
                  })
                : ''
            }
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('members-page', Members);
