/* eslint max-lines-per-function: 0 */
const BaseComponent = require('./Base');
const eventBus = require('../utils/EventBus');
const { getMenu } = require('../utils/storage');
const { setAuthMethod, setAdfsSettings, setAutoLogoffMinutes, setAdminLogin } = require('../utils/authStorage');
const sessionTimeout = require('../utils/sessionTimeout');
const handleRedirectLogout = require('../utils/handleRedirectLogout');

const { UPDATE_CONTAINER_VIEW } = require('../events');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Facilities = require('./Facilities');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const USSystems = require('./Systems');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExamTypes = require('./ExamTypes');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TagsAnnotations = require('./TagsAnnotations');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Workflow = require('./Workflow');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Members = require('./Members');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Credentials = require('./Credentials');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const QA = require('./QA');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Licenses = require('./Licenses');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Interfaces = require('./Interfaces');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LoginParameters = require('./LoginParameters');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Groups = require('./Groups');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExamTypesCPT = require('./ExamTypesCPT');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Tags = require('./Tags');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Worksheets = require('./Worksheets');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Insights = require('./Insights');

const UserAPI = require('../services/api/user');
const AuthSettingsAPI = require('../services/api/auth');
const userAPI = new UserAPI();
const authAPI = new AuthSettingsAPI();

class ExoContainer extends BaseComponent {
  constructor() {
    super();
    this._useEvents = false;
  }

  getView(view) {
    switch (view) {
      case 'Facilities & Specialities': {
        return '<facilities-page></facilities-page>';
        break;
      }
      case 'U/S Systems': {
        return '<systems-page></systems-page>';
        break;
      }
      case 'Map Exam Types & Worksheets': {
        return '<exam-types-page></exam-types-page>';
        break;
      }
      case 'Map Tags': {
        return '<tags-annotations-page></tags-annotations-page>';
        break;
      }
      case 'Workflow': {
        return '<workflow-page></workflow-page>';
        break;
      }
      case 'Members': {
        return '<members-page></members-page>';
        break;
      }
      case 'Credentials': {
        return '<credentials-page></credentials-page>';
        break;
      }
      case 'QA/Training': {
        return '<qa-page></qa-page>';
        break;
      }
      case 'Licenses': {
        return '<licenses-page></licenses-page>';
        break;
      }
      case 'Interfaces': {
        return '<interfaces-page></interfaces-page>';
        break;
      }
      case 'Authentication & Login': {
        return '<login-parameters-page></login-parameters-page>';
        break;
      }
      case 'User Groups': {
        return '<groups-page></groups-page>';
        break;
      }
      case 'Exam Types & CPT Codes': {
        return '<exam-types-cpt-page></exam-types-cpt-page>';
        break;
      }
      case 'Tags': {
        return '<tags-page></tags-page>';
        break;
      }
      case 'Worksheets': {
        return '<worksheets-page></worksheets-page';
        break;
      }
      case 'Insights': {
        return '<insights-page></insights-page>';
        break;
      }
      default: {
        return '<facilities-page></facilities-page>';
        break;
      }
    }
  }

  async connectedCallback() {
    const currentUser = await userAPI.getCurrentUser();
    setAutoLogoffMinutes(JSON.stringify(currentUser.autoLogoffMinutes));
    sessionTimeout();
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('adminLogin');
    if (!Boolean(myParam)) {
      const authSettings = await authAPI.get();

      if (authSettings) {
        setAuthMethod(authSettings.method);
        if (authSettings.adfs) {
          setAdfsSettings(JSON.stringify(authSettings.adfs));
        }
      }
    } else {
      setAdminLogin('true');
      setAuthMethod('EXO');
    }
    handleRedirectLogout();

    eventBus.register(UPDATE_CONTAINER_VIEW, ({ detail }) => {
      const childView = this.getView(detail.view);
      const style = `<style> ${this.styles()}</style>`;
      this.shadowRoot.innerHTML = `
        ${style}
        <div class='container'>
          ${childView}
        </div>
      `;
    });
  }

  styles() {
    return `
      .container {}
    `;
  }

  render() {
    const { option } = getMenu();
    const urlParams = new URLSearchParams(window.location.search);
    return `
      <div class='container'>
        ${this.getView(urlParams.get('redirect') === 'ussystems' ? 'U/S Systems' : option)}
      </div>
    `;
  }
}

customElements.define('exo-container', ExoContainer);
