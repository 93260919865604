const BaseService = require('./base');
const APIService = require('./APIService');

class UserAPI extends APIService {
  constructor() {
    super();
    this._baseService = new BaseService();
    this.getAccessControl = this.getAccessControl.bind(this);
  }

  get baseService() {
    return this._baseService;
  }

  async getCurrentUser() {
    const { data } = await this.httpClient({
      method: 'GET',
      url: `access-control/users/current`,
    });
    return data;
  }

  async getAccessControl({ id }) {
    return await this._baseService.httpClient({
      method: 'GET',
      url: `/access-control/users/${id}`,
    });
  }
}

module.exports = UserAPI;
