/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
/* @typescript-eslint/naming-convention: camelCase */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = require('./Card');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toggle = require('./Toggle');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HorizontalCard = require('./HorizontalCard');
const isSafari = require('../utils/isSafari');
const lensSVG = require('../../assets/lens.svg');

const ClinicDefinitionAPI = require('../services/api/clinicdefinition');
const AccessControlAPI = require('../services/api/accesscontrol');
const clinicDefinitionService = new ClinicDefinitionAPI();
const accessControlService = new AccessControlAPI();
const setScrollPosition = require('../utils/setScrollPosition');
const sortObjectsAlphabetically = require('../utils/sortObjectsAlphabetically');
const { ITEMS_PER_PAGE } = require('../utils/constants');

const dropDownSVG = require('../../assets/dropdown.svg');
const {
  FETCH_LDAP_GROUPS,
  FETCH_FACILITIES,
  FETCH_SPECIALITIES,
  FETCH_USER_GROUPS,
  FETCH_EXAM_TYPES,
  FETCH_LDAP_GROUP_USERS,
  UPDATE_SELECTED_FACILITY,
  UPDATE_SELECTED_SPECIALTY,
  UPDATE_SELECTED_USER,
  UPDATE_SELECTED_USER_GROUP,
  TOAST,
  SUCCESS,
  ERROR,
  PAGINATE_QA_USERS,
} = require('../events');

const FETCH_QA_SETTINGS = 'FETCH_QA_SETTINGS';

const eventBus = require('../utils/EventBus');

const DEFAULT_PERCENTAGES = {
  Attending: 10,
  Student: 100,
  Resident: 100,
  Fellow: 50,
  PA: 10,
};

class QA extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
    this._useScroll = true;
    this.editedFields = {};
    this.fetching = false;
    this.offset = 0;
  }

  static get observedAttributes() {
    return [
      'selectedspecialty',
      'selectedfacility',
      'selectedusergroup',
      'selecteduser',
      'selectedpercentage',
      'selectedusers',
      'selectedexams',
      'userfilter'
    ];
  }

  attachEvents() {
    const facilitySelect = this.shadowRoot.querySelector('.facility-select');
    const specialtySelect = this.shadowRoot.querySelector('.specialty-select');
    const percentageSelect = this.shadowRoot.querySelector('.percentage-select');
    const ugroupSelect = this.shadowRoot.querySelector('.ugroup-select');
    const saveSettings = this.shadowRoot.querySelector('.confirm');
    const inputs = this.shadowRoot.querySelectorAll('.reqForTraining');
    const searchInput = this.shadowRoot.querySelector('.user-search');
    /*
      TODO: Re-add when we tackle the select user functionality
      const userItems = this.shadowRoot.querySelectorAll('.user-name');
      const examItems = this.shadowRoot.querySelectorAll('.exam-name');
    */

    if (inputs) {
      inputs.forEach(input => {
        input.onkeydown = function(e) {
          if (
            !(
              (e.keyCode > 95 && e.keyCode < 106) ||
              (e.keyCode > 47 && e.keyCode < 58) ||
              e.keyCode === 8 ||
              e.keycode === 189
            )
          ) {
            return false;
          }
        };

        input.addEventListener('change', () => {
          // use for collecting updated fileds
          this.editedFields[input.name] = input.value;
        });
      });
    }

    facilitySelect.addEventListener('change', () => {
      eventBus.fire(UPDATE_SELECTED_SPECIALTY, {
        specialtyId: null,
      });
      eventBus.fire(UPDATE_SELECTED_USER_GROUP, {
        userGroupId: null,
      });
      eventBus.fire(UPDATE_SELECTED_FACILITY, {
        facilityId: facilitySelect.value,
      });

      eventBus.fire(FETCH_USER_GROUPS, {
        data: [],
      });

      eventBus.fire(FETCH_EXAM_TYPES, {
        data: null,
      });

      this.editedFields = {};
    });

    specialtySelect.addEventListener('change', () => {
      eventBus.fire(UPDATE_SELECTED_USER_GROUP, {
        userGroupId: null,
      });
      eventBus.fire(UPDATE_SELECTED_SPECIALTY, {
        specialtyId: specialtySelect.value,
      });
      eventBus.fire(FETCH_EXAM_TYPES, {
        data: null,
      });

      this.editedFields = {};
    });

    ugroupSelect.addEventListener('change', () => {
      eventBus.fire(UPDATE_SELECTED_USER_GROUP, {
        userGroupId: ugroupSelect.value,
      });

      this.editedFields = {};
    });

    percentageSelect.addEventListener('change', () => {
      const requiredExams = {};
      if (inputs) {
        inputs.forEach(input => {
          requiredExams[input.name] = input.value;
        });
        this.requiredExams = requiredExams;
      }
      this.selectedPercentage = percentageSelect.value;
    });

    if (saveSettings) {
      /*
      TODO: Re-add when we tackle the select user functionality
      userItems.forEach(userItem =>
        userItem.addEventListener('click', () => {
          if (this.selectedUsers.includes(userItem.id)) {
            this.selectedUsers = this.selectedUsers.filter(id => id !== userItem.id);
          } else {
            this.selectedUsers = [...this.selectedUsers, userItem.id];
          }
        }),
      );

      examItems.forEach(examItem =>
        examItem.addEventListener('click', () => {
          if (this.selectedExams.includes(examItem.id)) {
            this.selectedExams = this.selectedExams.filter(id => id !== examItem.id);
          } else {
            this.selectedExams = [...this.selectedExams, examItem.id];
          }
        }),
      );
      */
      saveSettings.addEventListener('click', async () => {
        const formattedExamTypeValues = [];

        this._data.exams.forEach(examType => {
          // check if field has been edited so we don't send fields
          // that don't need to be updated
          if (this.editedFields[examType.examType.id.toString()]) {
            const existingRecord = this._data.qa_settings.exam_type_qa_settings.find(
              setting => setting.exam_type === examType.examType.id,
            );

            formattedExamTypeValues.push({
              // don't send an id if field does not currently have settings
              id: existingRecord ? existingRecord.id : undefined,
              examsRequired: this.editedFields[examType.examType.id],
              examType: examType.examType.id,
            });
          }
        });

        try {
          await clinicDefinitionService
            .facilities({ facilityId: this.selectedFacility })
            .specialties({ specialtyId: this.selectedSpecialty })
            .userGroups({ id: this.selectedUserGroup })
            .qaSettings()
            .patch({
              payload: {
                examTypeQaSettings: formattedExamTypeValues,
                qaPercentageRequired: percentageSelect.value || 0,
                id: this._data.qa_settings.id,
              },
            });
          eventBus.fire(FETCH_QA_SETTINGS, {
            data: await clinicDefinitionService
              .facilities({ facilityId: this.selectedFacility })
              .specialties({ specialtyId: this.selectedSpecialty })
              .userGroups({ id: this.selectedUserGroup })
              .qaSettings()
              .get(),
          });
          eventBus.fire(TOAST, {
            items: [
              {
                type: SUCCESS,
                message: 'QA Setting saved',
              },
            ],
          });
        } catch (error) {
          eventBus.fire(TOAST, {
            items: [
              {
                type: ERROR,
                message: 'Error saving QA Settings',
              },
            ],
          });
        }
        this.editedFields = {};
      });
    }

    if (searchInput) {
      if (this.userFilter) {
        searchInput.defaultValue = this.userFilter;
        const filterLength = this.userFilter.length;
        searchInput.setSelectionRange(filterLength, filterLength);
      }

      searchInput.addEventListener('keyup', e => {
        if (e.key === 'Enter') {
          this.userFilter = this.currentFilter;
        } else {
          this.currentFilter = e.currentTarget.value;
        }
      });
      searchInput.focus();
    }
  }

  async connectedCallback() {
    eventBus.registerOnce(UPDATE_SELECTED_FACILITY, async ({ detail }) => {
      this.selectedFacility = detail.facilityId;
      eventBus.fire(FETCH_SPECIALITIES, {
        data: await clinicDefinitionService
          .facilities({ facilityId: detail.facilityId })
          .specialties()
          .get({
            parameters: {
              is_enabled: true,
            },
          }),
      });
    });

    eventBus.registerOnce(UPDATE_SELECTED_SPECIALTY, async ({ detail }) => {
      this.selectedSpecialty = detail.specialtyId;
      if (!detail.specialtyId) {
        return;
      }
      eventBus.fire(FETCH_USER_GROUPS, {
        data: await clinicDefinitionService
          .facilities({ facilityId: this.selectedFacility })
          .specialties({ specialtyId: detail.specialtyId })
          .userGroups()
          .get(),
      });
    });

    eventBus.registerOnce(UPDATE_SELECTED_USER_GROUP, async ({ detail }) => {
      this.selectedUserGroup = detail.userGroupId;
      this.userFilter = '';
      this.selectedPercentage = [];
      if (!detail.userGroupId) {
        eventBus.fire(FETCH_LDAP_GROUP_USERS, {
          data: [],
        });
      } else {
        eventBus.fire(FETCH_LDAP_GROUP_USERS, {
          data: await accessControlService
            .userGroups({ groupId: detail.userGroupId })
            .users()
            .get({ parameters: { specialtyId: this.selectedSpecialty, limit: ITEMS_PER_PAGE } }),
        });

        eventBus.fire(FETCH_EXAM_TYPES, {
          data: await clinicDefinitionService
            .examTypes({ facilityId: this.selectedFacility, specialtyId: this.selectedSpecialty })
            .get({ parameters: { isActive: true } }),
        });

        eventBus.fire(FETCH_QA_SETTINGS, {
          data: await clinicDefinitionService
            .facilities({ facilityId: this.selectedFacility })
            .specialties({ specialtyId: this.selectedSpecialty })
            .userGroups({ id: detail.userGroupId })
            .qaSettings()
            .get(),
        });
      }
      this.selectedPercentage = this.getDefaultSelectedPercentage();
    });

    eventBus.registerOnce(UPDATE_SELECTED_USER, ({ detail }) => {
      this.selectedUser = detail.userId;
    });

    this.listenForEvents(
      [
        { event: FETCH_FACILITIES, key: 'facilities' },
        { event: FETCH_SPECIALITIES, key: 'specialities' },
        { event: FETCH_USER_GROUPS, key: 'groups' },
        { event: FETCH_LDAP_GROUPS, key: 'ldap' },
        { event: FETCH_LDAP_GROUP_USERS, key: 'users' },
        { event: FETCH_EXAM_TYPES, key: 'exams' },
        { event: FETCH_QA_SETTINGS, key: 'qa_settings' },
      ],
      eventBus,
    );

    eventBus.fire(FETCH_FACILITIES, {
      data: await clinicDefinitionService.facilities().get({
        parameters: {
          is_enabled: true,
        },
      }),
    });

    eventBus.registerOnce(PAGINATE_QA_USERS, async ({ detail }) => {
      if (!this.fetching) {
        this.userListPosition = detail.listPosition;
        this.fetching = true;
        this.offset = this._data.users.results.length;

        const userGroupUsersData = await accessControlService
          .userGroups({ groupId: this.selectedUserGroup })
          .users()
          .get({ parameters: { specialtyId: this.selectedSpecialty, limit: ITEMS_PER_PAGE, offset: this.offset } });

        eventBus.fire(FETCH_LDAP_GROUP_USERS, {
          data: {
            ...userGroupUsersData,
            results: [...this._data.users.results, ...userGroupUsersData.results],
          },
        });

        this.fetching = false;
      }
    });
  }

  set userListPosition(newUserListPosition) {
    this.setAttribute('userListPosition', newUserListPosition);
  }

  get userListPosition() {
    return this.getAttribute('userListPosition');
  }

  get selectedUsers() {
    return this.getAttribute('selectedUsers') ? JSON.parse(this.getAttribute('selectedUsers')) : [];
  }

  set selectedUsers(newSelectedUsers) {
    this.setAttribute('selectedUsers', JSON.stringify(newSelectedUsers));
  }

  get selectedExams() {
    return this.getAttribute('selectedExams') ? JSON.parse(this.getAttribute('selectedExams')) : [];
  }

  set selectedExams(newSelectedExams) {
    this.setAttribute('selectedExams', JSON.stringify(newSelectedExams));
  }

  get selectedFacility() {
    return this.getAttribute('selectedFacility');
  }

  set selectedFacility(newSelectedFacility) {
    this.setAttribute('selectedFacility', newSelectedFacility !== null ? newSelectedFacility : '');
  }

  get selectedSpecialty() {
    return this.getAttribute('selectedSpecialty');
  }

  set selectedSpecialty(newSelectedSpecialty) {
    this.userListPosition = 0;
    this.setAttribute('selectedSpecialty', newSelectedSpecialty !== null ? newSelectedSpecialty : '');
  }

  get selectedUserGroup() {
    return this.getAttribute('selectedUserGroup');
  }

  set selectedUserGroup(newSelectedUserGroup) {
    this.setAttribute('selectedUserGroup', newSelectedUserGroup !== null ? newSelectedUserGroup : '');
  }

  get selectedUser() {
    return this.getAttribute('selectedUser');
  }

  set selectedUser(newSelectedUser) {
    this.setAttribute('selectedUser', newSelectedUser);
  }

  get selectedPercentage() {
    return this.getAttribute('selectedPercentage');
  }

  set selectedPercentage(newSelectedPercentage) {
    this.setAttribute('selectedPercentage', newSelectedPercentage);
  }

  get requiredExams() {
    return this.getAttribute('requiredExams') ? JSON.parse(this.getAttribute('requiredExams')) : {};
  }

  set requiredExams(newRequiredExams) {
    this.setAttribute('requiredExams', JSON.stringify(newRequiredExams));
  }

  get userFilter() {
    return this.getAttribute('userFilter');
  }

  set userFilter(newUserFilter) {
    this.setAttribute('userFilter', newUserFilter);
  }

  styles() {
    return `
      .title {
        margin-bottom: 3%;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 50px;
        letter-spacing: 0.566667px;
        color: #2C2C2E;
      }

      .select-box {
        width: 23%;
        display: inline-block;
      }

      select {
        border: 1px solid #007aff;
        background-color: #ffffff;
        border-radius: 10px;
        width: 100%;
        float: left;
        padding: 15px 20px;
        outline: none;
        -webkit-appearance: none;
        -ms-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin-left: 5%;
      }

      .select-wrapper {
        width: 90%;
        margin-top: 3%;
        background-color: #FFF;
        display: inline-block;
        position: relative;
      }
      
      .select-wrapper::before {
        content: url(${dropDownSVG});
        position: absolute;
        right: 0%;
        top: 21%;
        pointer-events: none;
      }

      exo-card-title {
        padding-left: 6%;
        ${isSafari() ? 'width: 50%;' : ''}
      }

      .title-wrapper {
        margin-left: 6%;
      }

      .lists {
        display: flex;
        max-height: 350px;
      }

      .head-section {
        display: flex;
        justify-content: space-between;
        padding-top: 5%;
      }

      .divider {
        margin: 0 auto;
        width: 90%;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }

      .long-divider {
        margin-top: 3%;
        width: 77%;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }

      .long-span {
        margin-left: 50%;
      }

      .items {
        padding: 0;
        width: 100%;
        max-height: 400px;
        margin-top: 1%;
        overflow: auto;
      }

      .text-input {
        list-style: none;
        padding: 3% 0 3% 2%;
      }

      .text-input > input {
        border:none;
        margin-left: 5%;
        border-bottom: 1px solid #DBDBDB;
        padding: 5px 10px;
        width: 80%;
        outline: none;
      }

      .enable-all {
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: normal;
        width: 90%;
        margin: 0 auto;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
        padding-bottom: 3%;
      }

      .card-container {
        margin-top: 3%;
      }

      .enable-all-label {
        margin-top: 1%;
        margin-right: 2%;
        padding-bottom: 5%;
      }

      .enable-all-label {
        margin-top: 1%;
        margin-right: 2%;
      }
    
      exo-card-button:last-child {
        margin-left: 10%;
      }

      exo-card-button:last-child {
        margin-left: 10%;
      }

      .card-container {
        display: flex;
      }

      .large-card {
        width: 100%;
      }

      .exam-name {
        width: 50%;
        background-color: #f7f7f7;
        border-radius: 5px;
        padding: 2% 0% 2% 1%;
        display: inline-block;
      }

      .highlight {
        border: 1px solid #007AFF;
        box-shadow: 0px 0px 2px #007AFF;
        background: #ffffff;
      }

      .reqForTraining {
        float: right;
        margin-right: 14%;
        border:none;
        padding: 2%;
        border-bottom: 1px solid #DBDBDB;
        outline: none;
      }

      .no-user-text {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(44, 44, 46, 0.503087);
      }

      .no-exams-text {
        width: 100%;
        height: 445px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(44, 44, 46, 0.503087);
      }

      exo-card {
        width: 25%;
      }

      horizontal-card.examTypes {
        width: 67%;
        margin-left: 3%;
      }

      button {
        border: 2px solid black;
        background-color: white;
        border-radius: 5px;
        color: black;
        padding: 14px 28px;
        font-size: 16px;
        cursor: pointer;
      }

      .selected {
        border: 1px solid #007AFF;
        box-shadow: 0px 0px 2px #007AFF;
        background: #ffffff;
      }

      .confirm {
        border-color: #007AFF;
        float: right;
        margin-right: 3%;
        color: #007AFF;
        margin-left: 2%;
      }

      .list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .user-name {
        cursor: pointer;
      }

      .users-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px;
      }
      .users-count {
        margin-left: 8px;
        white-space: nowrap;
        color: rgba(44, 44, 46, 0.5031);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }
      .search-container {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        height: 26px;
        max-width: 160px;
        background: #F2F2F7;
        border-radius: 5px;
      }
      .lens {
        margin-left: 10px;
      }
      input, input:focus {
        width: 70%;
        margin-left: 4px;
        border: none;
        outline: none;
        background: #F2F2F7;
      }
    `;
  }

  handleScroll() {
    const qaSettingsList = this.shadowRoot.querySelector('#qa-settings-list');

    if (qaSettingsList) {
      qaSettingsList.addEventListener('scroll', event => {
        this.qaSettingsListPosition = event.path[0].scrollTop;
      });

      qaSettingsList.scrollTo({ top: this.qaSettingsListPosition });
    }

    const userList = this.shadowRoot.querySelector('#users-list');

    if (userList) {
      userList.addEventListener('scroll', event => {
        this.userListPosition = setScrollPosition(event);
      });

      userList.scrollTo({ top: this.userListPosition });
    }
  }

  getFilteredUsers(items) {
    const lowerCaseFilter = this.userFilter.toLowerCase();
    return items.filter(user => user.username.toLowerCase().includes(lowerCaseFilter)
      || (user.firstName && user.firstName.toLowerCase().includes(lowerCaseFilter)) 
      || (user.lastName && user.lastName.toLowerCase().includes(lowerCaseFilter)));
  }

  renderExoCard({ title, divider, items, card, operation, nameKey, placeholderTitle, pagination, totalCount }) {
    if (card === 'users' && this.userFilter) {
      items = this.getFilteredUsers(items);
    }

    return `
      <exo-card
        page="QA"
        class="${this.selectedCard !== card && this.operation !== 'view' ? 'blur' : ''}"
        ${pagination ? `pagination totalCount=${totalCount} itemscount=${items.length} offset=${items.length}` : ''} >
        <div class="head-section ${card}">
          <exo-card-title ${
            placeholderTitle ? `placeholderTitle="${placeholderTitle}"` : ''
          } title="${title}"></exo-card-title>
        </div>

        ${
          card === 'users'
            ? `
              <div class="users-container">
                <div class="search-container">
                  <svg height="13" width="13" class="lens">
                    <image xlink:href="${lensSVG}" height="13" width="13" />
                  </svg>
                  <input type="text" class="user-search" placeholder="Search User" />
                </div>
                <div class="users-count">${items.length} Users</div>
              </div >
              `
            : ''
        }
      
        <div class="divider">
          ${divider.map(d => `<span>${d}</span>`).join('')}
        </div>

        ${
          items && items.length
            ? `<ul class="items paginated" id="${card === 'users' ? 'users-list' : ''}">
          ${items
            .map(
              item =>
                `<exo-card-list-item
                  page="qa"
                  name="${item.name}"
                  operation="${operation}"
                  id="${item.id}"
                  card="${card}"
                  ${card === 'users' ? 'viewOnly' : ''}
                  model="${item.model}"
                  enabled="${item.enabled}"
                  facilityId="${this.selectedFacility}"
                  specialtyId="${this.selectedSpecialty}"
                  userId="${this.selectedUser}"
                  selectedItem="${this.selectedUser}"
                  class="${card === 'users' ? 'user-name' : ''}"
                  clickable="true"
                  ${
                    card === 'users' && this.selectedUsers && this.selectedUsers.includes(item.id.toString())
                      ? 'highlight'
                      : ''
                  }
                >
                ${
                  card === 'users' && item.lastName && item.firstName
                    ? `${item.lastName}, ${item.firstName}`
                    : item[nameKey]
                }
                </exo-card-list-item>`,
            )
            .join('')}
        </ul>`
            : `<div class='no-user-text'>No Users Found</div>`
        }

        ${
          card !== 'users' && operation === 'view' && items.length > 0
            ? `<div class="enable-all">
               <span class="enable-all-label">${
                 items.every(item => item.enabled) ? 'Disable All' : 'Enable All'
               } </span>
               <exo-toggle
                 card="${card}"
                 facilityId="${this.selectedFacility}"
                 specialtyId="${this.selectedSpecialty}"
                 userId="${this.selectedUser}"
                 all
                 enabled="${items.every(item => item.enabled)}"
                ></exo-toggle>
              </div>`
            : ''
        }
      </exo-card>
    `;
  }

  getRequiredExamsFromSettings(examId) {
    let requiredExams = null;
    if (this._data && this._data.qa_settings && this._data.qa_settings.exam_type_qa_settings) {
      const setting = this._data.qa_settings.exam_type_qa_settings.find(exam => exam.exam_type === examId);
      if (setting) {
        requiredExams = this.editedFields[setting.exam_type]
          ? this.editedFields[setting.exam_type]
          : setting.exams_required;
      }
    }
    return requiredExams;
  }

  getDefaultSelectedPercentage() {
    let defaultSelectedPercentage = this.selectedPercentage;

    if (!Boolean(defaultSelectedPercentage) && Boolean(this.selectedUserGroup)) {
      if (this._data && this._data.qa_settings) {
        const userGroups = this._data.groups || [];
        defaultSelectedPercentage = (
          String(this._data.qa_settings.qa_percentage_required) ||
          DEFAULT_PERCENTAGES[userGroups.find(ug => ug.id == this.selectedUserGroup).name] ||
          0
        ).toString();
      }
    }
    return defaultSelectedPercentage;
  }

  render() {
    return `
      <div>
        <div class="title">QA/Training</div>
        <div class="top-card-container">
          <horizontal-card page="qa">
             <div class="select-box">
                <div class="title-wrapper">
                 <exo-card-title placeholderTitle="Select the" title="Facility"></exo-card-title>
                </div>
               <div class="select-wrapper">
                  <select class="facility-select">
                  <option selected="selected" disabled>Select</option>
                     ${this._data &&
                       this._data.facilities &&
                       this._data.facilities
                         .map(
                           facility =>
                             `<option value="${facility.id}" ${
                               facility.id == this.selectedFacility ? 'selected' : ''
                             }> ${facility.name} </option>`,
                         )
                         .join('')}
                  </select>
                </div>
             </div>
             <div class="select-box">
                <div class="title-wrapper">
                 <exo-card-title placeholderTitle="Select the" title="Specialty"></exo-card-title>
                </div>
               <div class="select-wrapper">
                 <select class="specialty-select" ${
                   this._data && (!this._data.specialities || this._data.specialities.length === 0) ? 'disabled' : ''
                 }>
                 <option selected="selected" disabled>Select</option>
                   ${this._data &&
                     this._data.specialities &&
                     this._data.specialities
                       .map(
                         specialty =>
                           `<option value="${specialty.id}"  ${
                             specialty.id == this.selectedSpecialty ? 'selected' : ''
                           }> ${specialty.name} </option>`,
                       )
                       .join('')}
                 </select>
                </div>
              </div>

              <div class="select-box">
              <div class="title-wrapper">
               <exo-card-title placeholderTitle="Select the" title="User Group"></exo-card-title>
              </div>
             <div class="select-wrapper">
               <select class="ugroup-select" ${
                 this._data && (!this._data.groups || this._data.groups.length === 0) ? 'disabled' : ''
               }>
               <option selected="selected" disabled>Select</option>
                 ${this._data &&
                   this._data.groups &&
                   this._data.groups
                     .map(
                       ugroup =>
                         `<option value="${ugroup.id}"  ${ugroup.id == this.selectedUserGroup ? 'selected' : ''}> ${
                           ugroup.name
                         } </option>`,
                     )
                     .join('')}
               </select>
              </div>
            </div>

            <div class="select-box">
            <div class="title-wrapper">
             <exo-card-title placeholderTitle="Select the" title="% for QA"></exo-card-title>
            </div>
           <div class="select-wrapper">
             <select class="percentage-select" ${this._data && !this._data.qa_settings ? 'disabled' : ''}>
             <option selected="selected" disabled>Select</option>
               ${['0', '5', '10', '15', '20', '50', '100']
                 .map(
                   percentage =>
                     `<option value="${percentage}" ${
                       this._data && this._data.qa_settings && String(this.selectedPercentage) === percentage
                         ? 'selected'
                         : ''
                     }> ${percentage} </option>`,
                 )
                 .join('')}
             </select>
            </div>
          </div>
          </horizontal-card>
        </div>
        <div class="card-container">
          ${
            this._data && this._data.users && this._data.users.results && this.selectedUserGroup
              ? this.renderExoCard({
                  card: 'users',
                  title: 'Members',
                  nameKey: 'username',
                  divider: ['Users'],
                  operation: 'view',
                  selectedItem: this.selectedUser,
                  placeholderTitle: ' ',
                  pagination: !this.userFilter,
                  totalCount: this._data.users.count,
                  items: this._data.users.results,
                })
              : ''
          }

          ${
            this._data && this._data.users && this._data.exams
              ? `<horizontal-card page="qa" class="examTypes">
          <div class="select-box large-card">
            <div class="title-wrapper">
              <exo-card-title placeholderTitle="Training" title="Exam Types"></exo-card-title>
              ${
                this._data.exams.length
                  ? `<div class="long-divider">
                  <span> Exam Type </span>
                  <span class="long-span"> Enter # of Exam req. for training </span>
                </div>
                <div class="lists">
                  <ul class="items" id='qa-settings-list'>
                    ${this._data &&
                      this._data.exams &&
                      sortObjectsAlphabetically(this._data.exams, ['examType', 'name'])
                        .map(
                          exam =>
                            `<exo-card-list-item
                          page="QA"
                          name="${exam.examType.name}"
                          operation="view"
                          id="${exam.id}"
                          card="exams"
                          noColor
                          viewOnly
                        > 
                          <div class="list-item">
                            <div id="${exam.id}" class="exam-name ${
                              this.selectedExams && this.selectedExams.includes(exam.id.toString()) ? 'highlight' : ''
                            }"> ${exam.examType.name} </div>
                            <input class="reqForTraining" min="0" type="number" name="${
                              exam.examType.id
                            }" value="${this.getRequiredExamsFromSettings(exam.examType.id) ||
                              (this.requiredExams && this.requiredExams[exam.examType.id]) ||
                              exam.required}"}>
                          </div>
                        </exo-card-list-item>`,
                        )
                        .join('')}
                  </ul>
              </div>
              <button class="confirm"> Save Settings </button>`
                  : `<div class='no-exams-text'>No Exam Types Enabled</div>`
              }
            </div>
          </div>
          </horizontal-card>`
              : ''
          }
        </div>
      </div>
    `;
  }
}

customElements.define('qa-page', QA);
