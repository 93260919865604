const {
  FETCH_EXAM_TYPES,
  FETCH_SPECIALTY_EXAM_TYPES,
  FETCH_SYSTEMS,
  UPDATE_SELECTED_SPECIALTY,
  FETCH_SPECIALTY_TAGS,
  FETCH_EFFICIENCY,
  FETCH_TAGS,
  FETCH_WORKSHEETS,
} = require('../../events');

const eventBus = require('../../utils/EventBus');

const ClinicDefinitionAPI = require('../../services/api/clinicdefinition');
const { ITEMS_PER_PAGE } = require('../../utils/constants');

const clinicDefinitionAPI = new ClinicDefinitionAPI();

module.exports = async ({ id, page, facilityId }) => {
  eventBus.fire(UPDATE_SELECTED_SPECIALTY, {
    specialtyId: id,
  });

  if (page === 'exams') {
    eventBus.fire(FETCH_SPECIALTY_EXAM_TYPES, {
      data: await clinicDefinitionAPI.examTypes({ facilityId, specialtyId: id }).get(),
    });
    eventBus.fire(FETCH_EXAM_TYPES, {
      data: await clinicDefinitionAPI.examTypes().getEnabled(),
    });
    eventBus.fire(FETCH_WORKSHEETS, {
      data: await clinicDefinitionAPI.examWorksheets().getAll({ parameters: { isPublished: true } }),
    });
  }

  if (page === 'tags') {
    eventBus.fire(FETCH_SPECIALTY_TAGS, {
      data: await clinicDefinitionAPI
        .facilities({ facilityId })
        .specialties({ specialtyId: id })
        .mapTags()
        .getAll(),
    });

    eventBus.fire(FETCH_TAGS, {
      data: await clinicDefinitionAPI.globalTags().getAll({ offset: 0, limit: ITEMS_PER_PAGE }),
    });
  }

  if (page === 'systems') {
    eventBus.fire(FETCH_SYSTEMS, {
      data: await clinicDefinitionAPI
        .facilities({ facilityId })
        .specialties({ specialtyId: id })
        .ultrasoundSystems()
        .get(),
    });
  }

  if (page === 'workflow') {
    eventBus.fire(FETCH_EFFICIENCY, {
      data: await clinicDefinitionAPI.facilities().specialties({ specialtyId: id }),
    });
  }
};
