const APIService = require('../APIService');
const urlParams = require('../../../utils/urlParams');
const formatError = require('../../../utils/formatError');

class ExamWorksheets extends APIService {
  constructor() {
    super();
    this._url = `/clinic-definition/exam-worksheets/`;
  }

  async getAll({ parameters }) {
    try {
      const { data } = await this.httpClient.get(`${this._url}${urlParams(parameters)}`);
      return data.results;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }
}

module.exports = ExamWorksheets;
