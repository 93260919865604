import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@webcomponents/webcomponentsjs';
import '@webcomponents/custom-elements';
const routes = require('./routes');
const getWebUrl = require('./utils/getWebUrl');

document.addEventListener('DOMContentLoaded', async function() {
  const rootDiv = document.getElementById('root');
  rootDiv.innerHTML = (await routes[window.location.pathname]()) || routes['/']();
  const closeButton = document.querySelector('#close');
  if (closeButton) {
    closeButton.addEventListener('click', () => {
      localStorage.clear();
      window.location.href = getWebUrl(); // TODO: once we have all our buckets update this
    });
  }
});
