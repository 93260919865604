/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = require('./Card');
const ClinicDefinitionAPI = require('../services/api/clinicdefinition');
const { UPDATE_CRUD_OPERATION, UPDATE_SELECTED_FACILITY, FETCH_TAGS, PAGINATE } = require('../events');
const eventBus = require('../utils/EventBus');
const PlusSVG = require('../../assets/plus.svg');
const createGlobalTag = require('../operations/ui/createGlobalTag');
const deleteGlobalTag = require('../operations/ui/deleteGlobalTag');
const editGlobalTag = require('../operations/ui/editGlobalTag');
const setScrollPosition = require('../utils/setScrollPosition');
const clinicDefinitionService = new ClinicDefinitionAPI();
const { ITEMS_PER_PAGE } = require('../utils/constants');

class Tags extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
    this._useScroll = true;
    this.offset = 0;
  }

  styles() {
    return `
      .background {
        background: transparent;
        width: 100vw;
        position: absolute;
        left: 0px;
        top: 0;
        display: flex;
        height: 100vh;
      }

      .title {
        margin-bottom: 3%;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 50px;
        letter-spacing: 0.566667px;
        color: #2C2C2E;
      }

      .head-section {
        display: flex;
        justify-content: space-between;
        padding: 5%;
      }

      .divider {
        margin: 0 auto;
        width: 90%;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }

      .items {
        padding: 0;
        width: 100%;
        margin-top: 1%;
      }

      .enable-all {
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: normal;
        width: 90%;
        margin: 0 auto;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
        padding-bottom: 3%;
      }

      .buttons {
        flex-grow: 1;
        align-items: flex-end;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .card-label {
        text-align: left;
        margin-left: 5%;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: #969696;
      }

      label {
        text-align: left;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: #969696;
      }

      input {
        border:none;
        border-bottom: 1px solid #DBDBDB;
        width: 100%;
        margin-bottom: 5%;
        outline: none;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #2C2C2E;
      }

      .creation-input {
        border:none;
        border-bottom: 1px solid #DBDBDB;
        margin-left: 5%;
        padding: 5px 10px;
        width: 80%;
        outline: none;
      }

      .input-container {
        width: 95%;
        position: relative;
      }

      .floating-label {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        letter-spacing: 0.2px;
        color: rgba(44, 44, 46, 0.5);
        position: absolute;
        right: 12%;
      }

      .floating-label:hover {
        cursor: pointer;
      }

      .enable-all-label {
        margin-top: 1%;
        margin-right: 2%;
      }

      .button-icon {
        margin: 30% 5%;
      }

      exo-card-button:last-child {
        margin-left: 10%;
      }

      .tag {
        border: 1px solid #007AFF;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        list-style: none;
        text-align: center;
        margin: 1% 2%;
        padding: 10%;
        width: 100%;
        border-radius: 12px;
      }

      .tag-container {
        display: inline-block;
        min-width: 20%;
        max-width: 130px;
        margin: 1% 10px;
        padding: 2%;
      }

      .modal {
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: white;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        width: 290px;
        height: 129px;
        border: none;
        z-index: 10;
        padding: 10px;
      }

      .modal-overflow {
        left: -200px;
      }

      .tag-container:nth-last-child(-n+5) .modal {
        top: -40%;
      }

      .btn-save {
        border-color: #007AFF;
        color: #007AFF;
        margin-left: 0px;
        height: 50px;
        width: 140px;
      }

      .btn-delete {
        border-color: #FB5654;
        color: #FB5654;
        margin-right: 0px;
        height: 50px;
        width: 140px;
      }

      .hidden {
        display: none;
      }

      button {
        min-width: 30%;
        border: 2px solid black;
        background-color: white;
        border-radius: 5px;
        color: black;
        padding: 14px 28px;
        font-size: 16px;
        cursor: pointer;
      }

      .tag:hover {
        cursor: pointer;
      }

      .card-container {
        display: flex;
      }

      .subtitle {
        font-style: normal;
        font-weight: bold;
        margin-left: 5%;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.266667px;
      }

      exo-card {
        width: 50%;
        margin-right: 2%;
      }

      exo-card-button {
        position: relative;
      }

      .card-content {
        height: 80vh;
        display: flex;
        flex-direction: column;
      }

      ul {
        overflow: auto;
      }

      .blur {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
      }

      .list-header {
        display: flex;
        justify-content: space-between;
      }

      .loading {
        font-family: 'HelveticaNowDisplay', sans-serif;
        margin-right: 50px;
        color: rgba(44, 44, 46, 0.503087);
      }

      @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
        exo-card {
          width: 30%;
        }
      }

      @media only screen and (max-width: 768px) {
        exo-card {
          width: 50%;
        }
      }

      .card-container {
        overflow-y: auto;
      }
    `;
  }

  attachEvents() {
    const addBtn = this.shadowRoot.querySelector('.add-tag');
    const tags = this.shadowRoot.querySelectorAll('.tag');
    const backgrounds = this.shadowRoot.querySelectorAll('.background');
    const tagInput = this.shadowRoot.querySelector(`input[name="tag"]`);
    addBtn.addEventListener('click', async () => {
      const tagValue = tagInput.value;
      await createGlobalTag({
        tag: tagValue,
      });
    });

    addBtn.classList.add('hidden');
    tagInput.addEventListener('input', () => {
      const tagValue = tagInput.value;
      if (tagValue.length) {
        addBtn.classList.remove('hidden');
      } else {
        addBtn.classList.add('hidden');
      }
      this.tagValue = tagValue;
    });

    const closeAllModals = () => {
      const modals = this.shadowRoot.querySelectorAll(`.modal`);
      modals.forEach(modal => {
        modal.classList.add('hidden');
      });
      backgrounds.forEach(background => {
        background.classList.add('hidden');
      });
    };
    tags.forEach(tag => {
      tag.addEventListener('click', () => {
        closeAllModals();
        const tagId = tag.getAttribute('tagId');
        const modal = this.shadowRoot.querySelector(`.tag-${tagId}`);
        const background = this.shadowRoot.querySelector('.background');
        modal.classList.remove('hidden');
        background.classList.remove('hidden');

        const setModalPosition = () => {
          modal.setAttribute(
            'style',
            `top: ${tag.getBoundingClientRect().y + 25}px; left: ${tag.getBoundingClientRect().x +
              tag.getBoundingClientRect().width -
              30}px`,
          );
        };

        setModalPosition(tag, modal);

        window.addEventListener('resize', setModalPosition);

        background.addEventListener('click', () => {
          window.removeEventListener('resize', setModalPosition);
        });

        deleteBtn = modal.querySelector('.btn-delete');
        saveBtn = modal.querySelector('.btn-save');

        deleteBtn.addEventListener('click', async () => {
          await deleteGlobalTag({ tagId });
        });

        saveBtn.addEventListener('click', async () => {
          await editGlobalTag({
            id: tagId,
            tag: modal.querySelector(`input[name="tag-input"]`).value,
          });
        });
      });
    });

    backgrounds.forEach(background => {
      background.addEventListener('click', () => {
        closeAllModals();
      });
    });

    const tagsList = this.shadowRoot.querySelector(`#tags`);

    tagsList.addEventListener('scroll', () => {
      if (tagsList.offsetHeight + tagsList.scrollTop >= tagsList.scrollHeight) {
        const itemsCount = this._data.tags.results.length;
        const count = this._data.tags.count;
        if (itemsCount < count) {
          this.offset = this.offset + ITEMS_PER_PAGE;
          eventBus.fire(PAGINATE, {
            limit: ITEMS_PER_PAGE,
          });
        }
      }
    });
  }

  async connectedCallback() {
    eventBus.register(UPDATE_CRUD_OPERATION, ({ detail }) => {
      this.selectedCard = detail.card;
      this.operation = detail.operation;
    });

    eventBus.register(UPDATE_SELECTED_FACILITY, ({ detail }) => {
      this.selectedFacility = detail.facilityId;
    });

    eventBus.register(PAGINATE, async ({ detail }) => {
      this.fetching = true;
      this.offset = this._data.tags.results.length;
      const clinicDefinitionService = new ClinicDefinitionAPI();

      const tagsData = await clinicDefinitionService.globalTags().getAll({
        offset: this.offset,
        limit: detail.limit,
      });

      if (this._data.tags.results.length == this.offset) {
        this._data.tags.results = [...this._data.tags.results, ...tagsData.results];
      }

      this.fetching = false;
    });

    this.listenForEvents([{ event: FETCH_TAGS, key: 'tags' }], eventBus);

    eventBus.fire(FETCH_TAGS, {
      data: await clinicDefinitionService.globalTags().getAll({ offset: 0, limit: ITEMS_PER_PAGE }),
    });
  }

  static get observedAttributes() {
    return ['selectedcard', 'operation', 'selectedfacility', 'fetching'];
  }

  set selectedCard(newSelectedCard) {
    this.setAttribute('selectedCard', newSelectedCard);
  }

  get selectedCard() {
    return this.getAttribute('selectedCard') || 'facilities';
  }

  set selectedFacility(newSelectedFacility) {
    this.setAttribute('selectedFacility', newSelectedFacility);
  }

  get selectedFacility() {
    return this.getAttribute('selectedFacility');
  }

  set operation(newOperation) {
    this.setAttribute('operation', newOperation);
  }

  get operation() {
    return this.getAttribute('operation') || 'view';
  }

  get fetching() {
    return this.getAttribute('fetching');
  }

  set fetching(newFetching) {
    this.setAttribute('fetching', newFetching);
  }

  get tagListPosition() {
    return this.getAttribute('tagListPosition');
  }

  set tagListPosition(newPosition) {
    this.setAttribute('tagListPosition', newPosition);
  }

  handleScroll() {
    const tagsList = this.shadowRoot.querySelector('#tags');

    if (tagsList) {
      tagsList.addEventListener('scroll', event => {
        this.tagListPosition = setScrollPosition(event);
      });
      tagsList.scrollTo({ top: this.tagListPosition });
    }
  }

  renderExoCard({ items, card }) {
    return `
      <exo-card page="tags" class="${this.selectedCard !== card && this.operation !== 'view' ? 'blur' : ''}">
        <div class="card-content">
          <div class="head-section">
          </div>

          <label class="card-label" for="tag">
            Enter Tag
          </label>
          <div class="input-container">
            <input class="creation-input" name="tag">
            <span class="floating-label add-tag">
              <svg width="15" height="15" class="button-icon">
                <image xlink:href="${PlusSVG}" width="15" height="15" />
              </svg>
            </span>
          </div>
          <div class="list-header">
            <div class="subtitle">Tags</div>
            ${this.fetching === 'true' ? '<span class="loading">Loading...</span>' : ''}
          </div>
          <ul id="tags">
            ${items
              .map(
                item => `
            <div class="background hidden"></div>
            <div class="tag-container">
              <li class="tag" tagId="${item.id}">
                ${item.tag}
              </li>
              <div class="modal tag-${item.id} hidden">
                <label for="tag-input">
                  Tag
                </label>
                <input name="tag-input" value="${item.tag}">
                <div class="buttons">
                  <button class="btn-save"> Save Edit </button>
                  <button class="btn-delete"> Delete Tag </button>
                </div>
              </div>
            </div>`,
              )
              .join('')}
          </ul>
        </div>
      </exo-card>
    `;
  }

  render() {
    return `
      <div>
        <div class="title">Tags</div>
        <div class="card-container">
          ${
            this._data && this._data.tags
              ? this.renderExoCard({
                  card: 'tags',
                  operation: this.selectedCard === 'facilities' ? this.operation : 'view',
                  items: this._data.tags.results,
                })
              : `<span> Loading... </span>`
          }
        </div>
      </div>
    `;
  }
}
customElements.define('tags-page', Tags);
