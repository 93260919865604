/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
/* @typescript-eslint/naming-convention: camelCase */
/* eslint-disable @typescript-eslint/no-this-alias */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toggle = require('./Toggle');
const AlertSVG = require('../../assets/alert.svg');
const eventBus = require('../utils/EventBus');
const dicomOptions = require('../utils/dicomOptions');
const ClinicDefinitionAPI = require('../services/api/clinicdefinition');
const { TOGGLE_MODAL } = require('../events.js');

const clinicDefinitionAPI = new ClinicDefinitionAPI();
const clickModalCreate = require('../operations/ui/clickModalCreate');
const clickModalDelete = require('../operations/ui/clickModalDelete');
const clickModalDisable = require('../operations/ui/clickModalDisable');
const clickModalEdit = require('../operations/ui/clickModalEdit');
const clickModalConfirm = require('../operations/ui/clickModalConfirm');
const timezones = require('../utils/timezones');

const formatDicomTag = dicomValue => dicomValue.replace(',', '');

class ExoModal extends BaseComponent {
  constructor() {
    //super();
    super({ isAsync: true });
    this._useEvents = true;
    //this.attachEvents();
    eventBus.register(TOGGLE_MODAL, ({ detail }) => {
      this.operation = detail.operation;
      this.isCPTEnabled =
        detail && detail.instance && detail.instance.isCPTEnabled ? detail.instance.isCPTEnabled : null;
      this.hide = !this.hide;
      if (!this.hide) {
        if (detail && detail.instance && detail.instance.card === 'systems') {
          this.listenForEvents([{ event: 'FETCH_FACILITIES', key: 'facilities' }], eventBus);

          this.fetchSpecialties();
        }
        this.id = detail.instance.id;
        this.enabled = detail.instance.enabled;
        this.model = detail.instance.model;
        this.card = detail.instance.card;
        this.facilityId = detail.instance.facilityId;
        this.specialtyId = detail.instance.specialtyId;
        this.description = detail.instance.description;
        this.professionalFee = detail.instance.professionalFee;
        this.technicalFee = detail.instance.technicalFee;
        this.subText = detail.instance.subText;
        this.examTypeId = detail.instance.examTypeId;
        this.groupId = detail.instance.groupId;
        this.email = detail.instance.email;
        this.username = detail.instance.username;
        this.firstName = detail.instance.firstName;
        this.lastName = detail.instance.lastName;
        this.category = detail.instance.category;
        this.dicomTag = detail.instance.dicomTag;
        this.tag = detail.instance.tag;
        this.systemName = '';
        this.dicomValue = '';
        this.timezone = detail.instance.timezone;
        this.abbreviation = detail.instance.abbreviation;
        this.name = detail.instance.name;
      }
    });
  }

  async fetchSpecialties() {
    eventBus.fire('FETCH_FACILITIES', {
      data: await clinicDefinitionAPI.facilities().get({
        parameters: {
          is_enabled: true,
        },
      }),
    });
  }

  static get observedAttributes() {
    return [
      'hide',
      'name',
      'category',
      'id',
      'operation',
      'card',
      'enabled',
      'model',
      'email',
      'username',
      'firstname',
      'lastname',
      'tag',
      'dicomtag',
      'dicomvalue',
      'timezone',
      'abbreviation',
      'isCPTEnabled',
      'specialtyId',
    ];
  }

  attachEvents() {
    const self = this;
    getSelectValueByName = name => {
      const el = this.shadowRoot.querySelector(`select[name="${name}"]`);
      return el && el.value;
    };
    if (this.operation === 'delete') {
      const confirmButton = this.shadowRoot.querySelector('.confirm');

      if (confirmButton) {
        confirmButton.addEventListener('click', () => {
          clickModalDelete({
            values: {
              facilityId: this.facilityId,
              specialtyId: this.specialtyId,
              examTypeId: this.card === 'examTypeCPT' ? this.id : '',
              systemId: this.card === 'systems' ? this.id : '',
              examTypeId: this.examTypeId,
              id: this.id,
              groupId: this.groupId,
            },
            card: this.card,
          });
          self.operation = null;
          eventBus.fire(TOGGLE_MODAL, {});
        });
        this.shadowRoot.querySelector('.cancel').addEventListener('click', () => {
          self.operation = null;
          eventBus.fire(TOGGLE_MODAL, {});
        });
      }
    } else if (this.operation === 'create') {
      const cancelButton = this.shadowRoot.querySelector('.cancel');

      if (cancelButton) {
        cancelButton.addEventListener('click', () => {
          self.operation = null;
          eventBus.fire(TOGGLE_MODAL, {});
        });
      }

      const facilitySelect = this.shadowRoot.querySelector('[name="facilitySelect"]');
      const specialtySelect = this.shadowRoot.querySelector('[name="specialtySelect"]');

      if (facilitySelect) {
        facilitySelect.addEventListener('change', () => {
          const newSpecialties = this._data.facilities
            .find(facility => facility.id === Number(facilitySelect.value))
            .specialties.filter(specialty => specialty.isEnabled);
          specialtySelect.innerHTML = `
            ${newSpecialties.map(specialty => `<option value=${specialty.id}>${specialty.name}</option>`)}
          `;
        });
      }

      if (this.shadowRoot.querySelector('.dicom-link')) {
        this.shadowRoot.querySelector('.dicom-link').addEventListener('click', () => {
          const dicomTagSelect = this.shadowRoot.querySelector('.dicom-select');
          dicomTagSelect.classList.add('hidden');
          this.shadowRoot.querySelector('.dicom-select-link').classList.remove('hidden');
          this.shadowRoot.querySelector('.dicom-input').classList.remove('hidden');
          this.shadowRoot.querySelector('.dicom-link').classList.add('hidden');
        });

        this.shadowRoot.querySelector('.dicom-select-link').addEventListener('click', () => {
          const dicomTagSelect = this.shadowRoot.querySelector('.dicom-select');
          dicomTagSelect.classList.remove('hidden');
          this.shadowRoot.querySelector('.dicom-select-link').classList.add('hidden');
          this.shadowRoot.querySelector('.dicom-input').classList.add('hidden');
          this.shadowRoot.querySelector('.dicom-link').classList.remove('hidden');
        });

        const dicomSelect = this.shadowRoot.querySelector('.dicom-select');
        dicomSelect.addEventListener('change', () => {
          self.dicomValue = dicomSelect.value;
          self.systemName = this.shadowRoot.querySelector('.systemName').value;
        });
      }

      const confirmButton = this.shadowRoot.querySelector('.confirm');

      if (confirmButton) {
        confirmButton.addEventListener('click', () => {
          const name = this.shadowRoot.querySelector('input').value;
          const enabled = this.shadowRoot.querySelector('exo-toggle').enabled;
          const timezone = this.shadowRoot.getElementById('timezone');
          const model = this.shadowRoot.querySelector('select');
          const getInputValueByName = name => {
            const el = this.shadowRoot.querySelector(`input[name="${name}"]`);
            return el && el.value;
          };

          clickModalCreate({
            values: {
              name,
              enabled,
              model: model ? model.value : null,
              specialtyId: this.specialtyId,
              facilityId: this.facilityId,
              technicalFee: getInputValueByName('technicalFee'),
              professionalFee: getInputValueByName('professionalFee'),
              description: getInputValueByName('description'),
              abbreviation: getInputValueByName('abbreviation'),
              category: getSelectValueByName('category'),
              systemModel: getSelectValueByName('model'),
              aeTitle: getInputValueByName('aeTitle'),
              specialty: getSelectValueByName('specialtySelect'),
              systemDicomTag: formatDicomTag(getSelectValueByName('dicomTagSelect') || ''),
              email: getInputValueByName('email'),
              password: getInputValueByName('password'),
              userName: getInputValueByName('userName'),
              lastName: getInputValueByName('lastName'),
              firstName: getInputValueByName('firstName'),
              examTypeId: this.examTypeId,
              dicomTag: formatDicomTag(this.dicomValue || ''),
              groupId: this.groupId,
              timezone: timezone ? timezone.value : null,
              isCPTEnabled: this.isCPTEnabled,
            },
            card: this.card,
          });

          eventBus.fire(TOGGLE_MODAL, {});
        });
      }
    } else if (this.operation === 'edit') {
      const facilitySelect = this.shadowRoot.querySelector('[name="facilitySelect"]');
      const specialtySelect = this.shadowRoot.querySelector('[name="specialtySelect"]');

      if (facilitySelect) {
        facilitySelect.addEventListener('change', () => {
          const newSpecialties = this._data.facilities
            .find(facility => facility.id === Number(facilitySelect.value))
            .specialties.filter(specialty => specialty.isEnabled);
          specialtySelect.innerHTML = `
            ${newSpecialties.map(specialty => `<option value=${specialty.id}>${specialty.name}</option>`)}
          `;
        });
      }
      if (this.shadowRoot.querySelector('.dicom-link')) {
        this.shadowRoot.querySelector('.dicom-link').addEventListener('click', () => {
          const dicomTagSelect = this.shadowRoot.querySelector('.dicom-select');
          dicomTagSelect.classList.add('hidden');
          this.shadowRoot.querySelector('.dicom-select-link').classList.remove('hidden');
          this.shadowRoot.querySelector('.dicom-input').classList.remove('hidden');
          this.shadowRoot.querySelector('.dicom-link').classList.add('hidden');
        });

        this.shadowRoot.querySelector('.dicom-select-link').addEventListener('click', () => {
          const dicomTagSelect = this.shadowRoot.querySelector('.dicom-select');
          dicomTagSelect.classList.remove('hidden');
          this.shadowRoot.querySelector('.dicom-select-link').classList.add('hidden');
          this.shadowRoot.querySelector('.dicom-input').classList.add('hidden');
          this.shadowRoot.querySelector('.dicom-link').classList.remove('hidden');
        });

        const dicomSelect = this.shadowRoot.querySelector('.dicom-select');
        dicomSelect.addEventListener('change', () => {
          self.dicomValue = dicomSelect.value;
        });
      }
      if (this.shadowRoot.querySelector('.confirm')) {
        this.shadowRoot.querySelector('.confirm').addEventListener('click', () => {
          const name = this.shadowRoot.querySelector('input').value;
          const enabled = this.shadowRoot.querySelector('exo-toggle').enabled;
          const model = this.shadowRoot.querySelector('select');
          const timezone = this.shadowRoot.getElementById('timezone');
          const getInputValueByName = name => {
            const el = this.shadowRoot.querySelector(`input[name="${name}"]`);
            return el && el.value;
          };

          clickModalEdit({
            values: {
              facilityId: this.facilityId,
              specialtyId: this.specialtyId,
              systemId: this.card === 'systems' ? this.id : '',
              examTypeId: this.examTypeId,
              category: this.category,
              technicalFee: getInputValueByName('technicalFee'),
              subText: getInputValueByName('description'),
              professionalFee: getInputValueByName('professionalFee'),
              description: getInputValueByName('description'),
              category: getSelectValueByName('category'),
              email: getInputValueByName('email'),
              userName: getInputValueByName('userName'),
              firstName: getInputValueByName('firstName'),
              lastName: getInputValueByName('lastName'),
              tag: getInputValueByName('tag'),
              aeTitle: getInputValueByName('aeTitle'),
              specialty: getSelectValueByName('specialtySelect'),
              systemDicomTag: formatDicomTag(getSelectValueByName('dicomTagSelect') || ''),
              examTypeId: this.examTypeId,
              name,
              enabled,
              id: this.id,
              model: model ? model.value : null,
              groupId: this.groupId,
              timezone: timezone ? timezone.value : null,
              abbreviation: getInputValueByName('abbreviation') || null,
            },
            card: this.card,
          });
          self.operation = null;
          eventBus.fire(TOGGLE_MODAL, {});
        });
      }

      if (this.shadowRoot.querySelector('.cancel')) {
        this.shadowRoot.querySelector('.cancel').addEventListener('click', () => {
          self.operation = null;
          eventBus.fire(TOGGLE_MODAL, {});
        });
      }
    } else if (this.operation === 'disable') {
      const confirmButton = this.shadowRoot.querySelector('.confirm');

      if (confirmButton) {
        confirmButton.addEventListener('click', () => {
          clickModalDisable({
            values: {
              facilityId: this.facilityId,
              specialtyId: this.specialtyId,
            },
            card: this.card,
          });
          self.operation = null;
          eventBus.fire(TOGGLE_MODAL, {});
        });
        this.shadowRoot.querySelector('.cancel').addEventListener('click', () => {
          self.operation = null;
          eventBus.fire(TOGGLE_MODAL, {});
        });
      }
    } else if (this.operation === 'confirm') {
      const confirmButton = this.shadowRoot.querySelector('.confirm');

      confirmButton.addEventListener('click', () => {
        clickModalConfirm({ values: { id: this.id }, card: this.card });
        self.operation = null;
        eventBus.fire(TOGGLE_MODAL, {});
      });
      this.shadowRoot.querySelector('.cancel').addEventListener('click', () => {
        self.operation = null;
        eventBus.fire(TOGGLE_MODAL, {});
      });
    }

    this.shadowRoot.querySelector('.background').addEventListener('click', () => {
      self.operation = null;
      eventBus.fire(TOGGLE_MODAL, {});
    });
  }

  getDicomValue() {
    const dicomSelect = this.shadowRoot.querySelector('.dicom-select').addEventListener('');
    return dicomSelect.value;
  }

  async connectedCallback() {
    this.shadowRoot.innerHTML = `
      <style>
        ${this.styles()}
      </style>
      ${await this.render()}
    `;

    this.attachEvents();
  }

  styles() {
    return `
      .background {
        background: black;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        opacity: 0.5;
        top: 0;
        display: flex;
        height: 100vh;
      }
      
      .modal {
        width: 25%;
        text-align: center;
        background-color: white;
        border-radius: 3px;
        position: absolute;
        left: 40%;
        top: ${this.card === 'cpt' ? '20' : '30'}%;
      }
      .section {
        margin: 5% 0%;
      }
      .title {
        font-weight: bold;
        font-size: 18px;
        line-height: 25px
        color: black;
        letter-spacing: 0.3px;
      }
      button {
        min-width: 30%;
        border: 2px solid black;
        background-color: white;
        border-radius: 5px;
        color: black;
        padding: 14px 28px;
        font-size: 16px;
        cursor: pointer;
      }
      .confirm {
        border-color: #007AFF;
        color: #007AFF;
        margin-left: 2%;
      }
      .cancel {
        border-color: #FB5654;
        color: #FB5654;
        margin-right: 2%;
      }
      .hide {
        visibility: hidden;
      }
      .subtitle {
        color: rgba(44, 44, 46, 0.5);
      }
      label, input {
        display: block;
      }
      label {
        text-align: left;
        margin-left: 5%;
        margin-bottom: 1%;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: #969696;
      }
      .toggle {
        float: right;
        margin-right: 5%;
        margin-top: 3%;
      }
      .toggle-label {
        font-size: 14px;
        line-height: 21px;
        margin-right: 10px;
      }
      .create-buttons {
        margin-top: 35%;
        margin-bottom: 5%;
      }
      .form-container {
        margin-top: 10%;
      }
      input {
        border:none;
        margin-left: 5%;
        margin-bottom: 1%;
        border-bottom: 1px solid #DBDBDB;
        padding: 5px 10px;
        width: 80%;
        outline: none;
        margin-right: 5%;
      }
      .hidden {
        display: none;
      }
      .dicom-select-link, .dicom-link {
        color: blue;
        float: right;
        margin-right: 10%;
      }
      .dicom-link {
        width: 100%;
        text-align: right;
        margin-right: 9%;
      }
      .dicom-select-link:hover, .dicom-link:hover {
        cursor: pointer;
      }
      .auth {
        padding: 5px;
      }
      select {
        border: none;
        width: 86%;
        float: left;
        padding: 5px 10px;
        border-bottom: 1px solid #dbdbdb;
        outline: none;
        margin-left: 5%;
        margin-bottom: 3%;
        margin-right: 5%;
      }
      .create.header {
        text-align: left;
        margin: 5% 0% 0% 5%;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.266667px;
      }
      select {
        padding: 5px 6px;
      }
      @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
        .modal {
          width: 70%;
          left: 15%;
        }
      }
      @media only screen and (max-width: 768px) {
        .modal {
          width: 70%;
          left: 15%;
        }
      }
    `;
  }

  set hide(newHide) {
    this.setAttribute('hide', newHide);
  }

  get hide() {
    const hideAttr = this.getAttribute('hide');
    return hideAttr === 'true' || hideAttr === '';
  }

  set name(newName) {
    this.setAttribute('name', newName);
  }

  get name() {
    return this.getAttribute('name');
  }

  set category(newCategory) {
    this.setAttribute('category', newCategory);
  }

  get category() {
    return this.getAttribute('category');
  }

  set dicomValue(newDicomValue) {
    this.setAttribute('dicomValue', newDicomValue);
  }

  get dicomValue() {
    return this.getAttribute('dicomValue');
  }

  set id(newId) {
    this.setAttribute('id', newId);
  }

  get id() {
    return this.getAttribute('id');
  }

  get email() {
    return this.getAttribute('email');
  }

  set email(newEmail) {
    this.setAttribute('email', newEmail);
  }

  get username() {
    return this.getAttribute('username');
  }

  set username(newUsername) {
    this.setAttribute('username', newUsername);
  }

  get firstName() {
    return this.getAttribute('firstName');
  }

  set firstName(newFirstName) {
    this.setAttribute('firstName', newFirstName);
  }

  get lastName() {
    return this.getAttribute('lastName');
  }

  set lastName(newLastName) {
    this.setAttribute('lastName', newLastName);
  }

  get operation() {
    return this.getAttribute('operation');
  }

  set operation(newOp) {
    this.setAttribute('operation', newOp);
  }

  get card() {
    return this.getAttribute('card');
  }

  set card(newCard) {
    this.setAttribute('card', newCard);
  }

  get timezone() {
    return this.getAttribute('timezone');
  }

  set timezone(newTimezone) {
    return this.setAttribute('timezone', newTimezone);
  }

  get abbreviation() {
    return this.getAttribute('abbreviation');
  }

  set abbreviation(newAbbreviation) {
    return this.setAttribute('abbreviation', newAbbreviation);
  }

  get isCPTEnabled() {
    return this.getAttribute('isCPTEnabled');
  }

  set isCPTEnabled(newIsCPTEnabled) {
    this.setAttribute('isCPTEnabled', newIsCPTEnabled);
  }

  get model() {
    return this.getAttribute('model');
  }

  set model(newModel) {
    this.setAttribute('model', newModel);
  }

  get enabled() {
    return this.getAttribute('enabled');
  }

  set enabled(newEnabled) {
    this.setAttribute('enabled', newEnabled);
  }

  get serial() {
    return this.getAttribute('serial');
  }

  set serial(newSerial) {
    this.setAttribute('serial', newSerial);
  }

  get softwareVersion() {
    return this.getAttribute('softwareVersion');
  }

  set softwareVersion(newSoftwareVersion) {
    this.setAttribute('softwareVersion', newSoftwareVersion);
  }

  get systemName() {
    return this.getAttribute('systemName');
  }

  set systemName(newSystemName) {
    this.setAttribute('systemName', newSystemName);
  }

  get manufacturer() {
    return this.getAttribute('manufacturer');
  }

  set manufacturer(newManufacturer) {
    this.setAttribute('manufacturer', newManufacturer);
  }

  get dicomTag() {
    return this.getAttribute('dicomTag');
  }

  set dicomTag(newDicomTag) {
    this.setAttribute('dicomTag', newDicomTag);
  }

  get tag() {
    return this.getAttribute('tag');
  }

  set tag(newTag) {
    this.setAttribute('tag', newTag);
  }

  getTitle({ card }) {
    switch (card) {
      case 'authentication':
        return 'Authentication';
      case 'facilities':
        return 'Facility';
      case 'specialities':
        return 'Specialty';
      case 'systems':
        return 'U/S System';
      case 'examTypeCPT':
        return 'Exam Type';
      case 'cpt':
        return 'CPT Code';
      case 'ugroups':
        return 'User Group';
      case 'users':
        return 'User';
      case 'tags':
        return 'Tag';
      default:
        return '';
    }
  }

  getLabel({ card }) {
    switch (card) {
      case 'systems':
        return 'System Name';
      default:
        return this.getTitle({ card });
    }
  }

  renderCreate({ card, isCPTEnabled }) {
    return `
      <div class="create header">
        <div class="subtitle"> Add your </div>
        <div class="title"> ${this.getTitle({ card })} </div>
      </div>
      ${
        card !== 'users' && card !== 'systems'
          ? `<div class="form-container">
          <label for="name">${this.getLabel({ card })}</label>
          <input name="name" type="name" autocomplete="false">
        </div>`
          : ''
      }
      ${
        card === 'systems'
          ? `
            <div class="form-container">
              <label for="aeTitle"> AE Title </label>
              <input name="aeTitle" maxlength="16">
              <label for="facilitySelect">Facility</label>
              <select name="facilitySelect">
                ${this._data.facilities
                  .map(facility => `<option value="${facility.id}">${facility.name}</option>`)
                  .join('')}
              </select>
              <label for="specialtySelect">Specialty</label>
              <select name="specialtySelect">
                ${this._data.facilities[0].specialties.map(
                  specialty =>
                    `<option value="${specialty.id}" ${Number(this.specialtyId) === specialty.id ? 'selected' : ''}
                    >${specialty.name}</option>`,
                )}
              </select>
              <div>
                <label for="dicomTagSelect">
                  Exo Works Receives performing physician from:
                </label>
                <select name="dicomTagSelect">
                  ${dicomOptions().map(
                    option => `
                    <option value="${option.value}" ${option.value === '0008,1050' ? 'selected' : ''}>
                      ${option.label} (${option.value})
                    </option>
                  `,
                  )}
                </select>
              </div>
            </div>
          `
          : ''
      }
      ${
        card === 'examTypeCPT'
          ? `
          <div class="form-container">
            <label for="abbreviation">
              Abbreviation
            </label>
            <input name="abbreviation" type="text">
          </div>
          <div class="form-container">
            <label for="category">
              Category
            </label>
            <select name="category">
              <option value="EXAM">Exam</option>
              <option value="PROCEDURE">Procedure</option>
            </select>
          </div>
        `
          : ''
      }
      ${
        card === 'cpt'
          ? `
          <div class="form-container">
            <label for="description">
              Description
            </label>
            <input name="description" type="text">
          </div>
        `
          : ''
      }
      ${
        card === 'users'
          ? `
            <div class="form-container">
              <label for="email">
                Email
              </label>
              <input name="email" type="email">
              <label for="username">
                Username
              </label>
              <input name="userName" type="text">
              <label for="password">
                Password
              </label>
              <input name="password" type="password">
              <label for="firstName">
                First Name
              </label>
              <input name="firstName" type="text">
              <label for="lastName">
                Last Name
              </label>
              <input name="lastName" type="text">
              <input name="specialtyId" class="hidden" value="${this.specialtyId}">
            </div> 
          `
          : ''
      }
      ${
        card === 'facilities'
          ? `
        <label for="name">Timezone</label>
        <select name="timezone" id="timezone">
        <option selected disabled>Select timezone...</option>
          ${timezones.map(
            timezone =>
              `<option>
              ${timezone}
            </option>`,
          )}
        </select>
      `
          : ''
      }
      <div class="toggle">
        <span class="toggle-label"> Enable </span>
        ${
          card === 'cpt'
            ? `<exo-toggle 
            isCPTEnabled="${isCPTEnabled}"
            enabled="false"
          ></exo-toggle>`
            : `<exo-toggle enabled="true"></exo-toggle>`
        }
      </div>
      <div class="create-buttons">
        <button class="cancel"> Cancel </button>
        <button class="confirm"> Save </button>
      </div>
    `;
  }

  renderDelete({ name }) {
    return `
      <div class="section">
        <svg width="32" height="32">
          <image xlink:href="${AlertSVG}" width="32" height="32" />
        </svg>
      </div>
      <div class="section title"> Delete "${name}"?</div>
      <div class="section">
        <button class="cancel"> Cancel </button>
        <button class="confirm"> Confirm </button>
      </div>
    `;
  }

  renderEdit({
    name,
    enabled,
    card,
    subText,
    email,
    username,
    tag,
    firstName,
    lastName,
    category,
    timezone,
    abbreviation,
  }) {
    const facilities = this._data.facilities;
    let itemFacility = null;
    if (card === 'systems' && facilities && this.specialtyId) {
      itemFacility = facilities.find(facility =>
        facility.specialties.some(specialty => specialty.id === Number(this.specialtyId)),
      );
    }

    return `
      <div class="create header">
        <div class="subtitle"> Edit your </div>
        <div class="title"> ${this.getTitle({ card })} </div>
      </div>
      ${
        card !== 'users' && card !== 'tags' && card !== 'systems'
          ? `<div class="form-container">
          <label for="name">${this.getTitle({ card })}</label>
            <input type="name" value="${(name || '').trim()}">
          </div>`
          : ''
      }
      ${
        card === 'systems'
          ? `
          <div class="form-container">
            <label for="aeTitle"> AE Title </label>
            <input name="aeTitle" value="${name}" disabled maxlength="16">
            <label for="facilitySelect">Facility</label>
            <select name="facilitySelect">
              ${
                facilities
                  ? facilities
                      .map(
                        facility =>
                          `<option value="${facility.id}" ${
                            itemFacility && facility.id === itemFacility.id ? 'selected' : ''
                          }>${facility.name}</option>`,
                      )
                      .join('')
                  : ''
              }
            </select>
            <label for="specialtySelect">Specialty</label>
            <select name="specialtySelect">
              ${
                itemFacility
                  ? itemFacility.specialties.map(
                      specialty =>
                        `<option value="${specialty.id}" ${
                          Number(this.specialtyId) === specialty.id ? 'selected' : ''
                        }>${specialty.name}</option>`,
                    )
                  : facilities
                  ? facilities[0].specialties.map(
                      specialty => `<option value="${specialty.id}">${specialty.name}</option>`,
                    )
                  : ''
              }
            </select>
            <div>
              <label for="dicomTagSelect">
                Exo Works Receives performing physician from:
              </label>
              <select name="dicomTagSelect">
                ${dicomOptions().map(
                  option => `
                  <option value="${option.value}" ${option.value === this.dicomTag ? 'selected' : ''}>
                    ${option.label} (${option.value})
                  </option>
                `,
                )}
              </select>
            </div>
          </div>`
          : ''
      }
      ${
        card === 'examTypeCPT'
          ? `
          <div class="form-container">
            <label for="abbreviation">
              Abbreviation
            </label>
            <input name="abbreviation" type="text" value="${(abbreviation || '').trim()}">
          </div>
          <div class="form-container">
            <label for="category">
              Category
            </label>
            <select name="category" value="${category}">
              <option value="EXAM" ${category === 'EXAM' ? 'selected' : ''}>Exam</option>
              <option value="PROCEDURE" ${category === 'PROCEDURE' ? 'selected' : ''}>Procedure</option>
            </select>
          </div>
        `
          : ''
      }
      ${
        card === 'cpt'
          ? `
          <div class="form-container">
            <label for="description">
              Description
            </label>
            <input name="description" type="text" value="${(subText || '').trim()}">
          </div>
        `
          : ''
      }
      ${
        card === 'users'
          ? `
            <div class="form-container">
              <label for="email">
                Email
              </label>
              <input name="email" type="email" value="${(email || '').trim()}">
              <label for="username">
                Username
              </label>
              <input name="userName" type="text" value="${(username || '').trim()}">
              <label for="firstName">
                First Name
              </label>
              <input name="firstName" type="text" value="${(firstName || '').trim()}">
              <label for="lastName">
                Last Name
              </label>
              <input name="lastName" type="text" value="${(lastName || '').trim()}">
              <input name="specialtyId" class="hidden" value="${this.specialtyId}">
            </div> 
          `
          : ''
      }
      ${
        card === 'tags'
          ? `
            <div class="form-container">
              <label for="tag">
                Tag
              </label>
              <input name="tag" value="${tag}">
            </div> 
          `
          : ''
      }
      ${
        card === 'facilities'
          ? `
        <label for="name">Timezone</label>
        <select name="timezone" id="timezone" defaultvalue=${timezone || 'default'}>
        <option value="default" selected disabled>Select timezone...</option>
          ${timezones.map(
            item => `<option value=${item} ${timezone === item && `selected`}>
              ${item}
            </option>`,
          )}
        </select>
      `
          : ''
      }
      <div class="toggle">
        <span class="toggle-label"> Enable </span>
        <exo-toggle enabled="${enabled}"></exo-toggle>
      </div>
      <div class="create-buttons">
        <button class="cancel"> Cancel </button>
        <button class="confirm"> Save </button>
      </div>
    `;
  }

  renderDisable({ text }) {
    return `
      <div class="section">
        <svg width="32" height="32">
          <image xlink:href="${AlertSVG}" width="32" height="32" />
        </svg>
      </div>
      <div class="section title"> ${text} </div>
      <div class="section">
        <button class="cancel"> Cancel </button>
        <button class="confirm"> Confirm </button>
      </div>
    `;
  }

  renderConfirm({ card, name }) {
    return `
      <div class="section auth">
        <svg width="32" height="32">
          <image xlink:href="${AlertSVG}" width="32" height="32" />
        </svg>
      </div>
      <div class="section title auth"> 
        ${card === 'authentication' ? `Are you sure you want to set ${name} as authentication method?` : ''}
      </div>
      <div class="section">
        <button class="cancel"> Cancel </button>
        <button class="confirm"> Confirm </button>
      </div>
    `;
  }

  render() {
    let renderFun = null;
    switch (this.operation) {
      case 'delete':
        renderFun = () => this.renderDelete({ name: this.name });
        break;
      case 'create':
        renderFun = () =>
          this.renderCreate({
            card: this.card,
            dicomValue: this.dicomValue,
            systemName: this.systemName,
            manufacturer: this.manufacturer,
            isCPTEnabled: this.isCPTEnabled,
          });
        break;
      case 'edit':
        renderFun = () =>
          this.renderEdit({
            name: this.name,
            enabled: this.enabled,
            category: this.category,
            card: this.card,
            model: this.model,
            subText: this.subText,
            technicalFee: this.technicalFee,
            professionalFee: this.professionalFee,
            description: this.description,
            email: this.email,
            tag: this.tag,
            password: this.password,
            username: this.username,
            firstName: this.firstName,
            lastName: this.lastName,
            dicomValue: this.dicomValue || this.dicomTag,
            timezone: this.timezone,
            abbreviation: this.abbreviation,
          });
        break;
      case 'disable':
        renderFun = () =>
          this.renderDisable({
            text: this.subText || 'Users associated with this specialty will be disabled',
          });
        break;
      case 'confirm':
        renderFun = () =>
          this.renderConfirm({
            card: this.card,
            name: this.name,
          });
        break;
      default:
        renderFun = () => ``;
    }
    return `
      <div>
        <div class="background ${this.hide ? 'hide' : ''}"></div>
        <div class="modal ${this.hide ? 'hide' : ''}">
          ${renderFun()}    
        </div>
      </div>
    `;
  }
}

customElements.define('exo-modal', ExoModal);
